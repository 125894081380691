/**
 * Footer
 */
.Footer .Container {
    max-width: u(1320);
}

.Footer-top {
    background-color: $cc-black;
    color: $white;
    padding: u($spacing-unit--lg);

    &--custom {
        .Nav--extraNav {
            @include mq($from: $viewport--sm + 100) {
                margin-left: u(50);
            }
        }

        .Footer-item--newsletter {
            max-width: u(450);

            @include mq($from: $viewport--lg) {
                margin-left: u(50);
            }
        }
    }

    &--none {
        @include mq($from: $viewport--sm + 100, $until: $viewport--lg - 1) {
            grid-template-areas:
                "title ."
                "contact newsletter"
                ". ."
                "socialMedia craftVerification" !important;
        }

        @include mq($from: $viewport--lg) {
            grid-template-columns: auto 1fr 1fr !important;
            grid-template-areas:
                "title . ."
                ". contact newsletter"
                "socialMedia . craftVerification" !important;
        }

        .Footer-item--contact {
            @include mq($from: $viewport--lg    ) {
                margin-left: u(50);
            }
        }
    }

    @include mq($from: $viewport--sm + 100) {
        padding: u($spacing-unit * 2.5, $spacing-unit * 3.2, $spacing-unit * 3.8, $spacing-unit * 3.2);
        display: grid;
        grid-column-gap: u($spacing-unit--sm);
    }

    @include mq($from: $viewport--sm + 100, $until: $viewport--lg - 1) {
        grid-template-rows: repeat(4, auto);
        // grid-template-columns: repeat(2, 1fr);
        grid-template-columns: auto 1fr;
        grid-template-areas:
            "title ."
            "contact extraNav"
            "newsletter newsletter"
            "socialMedia craftVerification";
    }

    @include mq($from: $viewport--lg) {
        grid-template-rows: repeat(3, auto);
        grid-template-columns: auto 1fr auto;
        grid-column-gap: u($spacing-unit--sm);
        grid-template-areas:
            "title . ."
            "contact extraNav newsletter"
            "socialMedia . craftVerification";
    }


}

.Footer-title {
    max-width: u(200px);
    grid-area: title;
    display: flex;
    flex-direction: column;
    margin-bottom: u($spacing-unit / 1.5);

    &--icon {
        @include dimensions(18, 22);
        margin-bottom: u($spacing-unit--sm);
        fill: $white;
        opacity: .3;
    }

    @include mq($from: $viewport--lg) {
        // margin-bottom: 0;
    }
}

.Footer-subtitle {
    line-height: 1.18;
    margin-bottom: u($spacing-unit--sm);
}

.Footer-item {

    @include mq($from: $viewport--lg) {

        &:nth-of-type(1) {
            justify-self: end;
        }
    }

    @include mq($from: $viewport--md) {

        &:nth-of-type(1) {
            justify-self: start;
            margin-bottom: u($spacing-unit);
        }
    }

    @include mq($until: $viewport--md - 1) {
        margin-top: u($spacing-unit--lg);
    }

    a:not(.Button) {
        color: currentColor;
        text-decoration: none;

        &:hover {
            color: currentColor;
            text-decoration: underline;
        }
    }

    .Button--secondary {

        &,
        &:hover {
            color: $white;
        }
    }

    p {
        margin-bottom: u($spacing-unit--sm);
    }

    :last-child {
        margin-bottom: 0;
    }

    &--contact {
        @include mq($from: $viewport--sm + 100) {
            grid-area: contact;
        }
    }

    &--extraNav {
        @include mq($until: $viewport--xs + 149) {
            .Nav--extraNav {

                &:last-child {
                    margin: 0;
                }

                margin: u(0, 0, $spacing-unit--lg, 0);

            }
        }

        @include mq($from: $viewport--xs + 150) {
            display: flex;
            justify-content: space-between;

        }

        @include mq ($until: $viewport--md - 1) {
            max-width: u(320);
        }

        @include mq ($from: $viewport--md) {
            justify-content: space-around;
            max-width: u(440);
        }

        @include mq($from: $viewport--sm + 100) {
            grid-area: extraNav;
        }
    }

    &--newsletter {
        grid-area: newsletter;

        @include mq ($from: $viewport--md) {
            max-width: u(320);
        }

        @include mq($from: $viewport--lg, $until: $viewport--xl - 1) {
            max-width: u(270);
        }
    }

    &--socialMedia {
        grid-area: socialMedia;
        align-self: flex-start;

        @include mq($from: $viewport--sm + 100, $until: $viewport--lg - 1) {
            margin-top: u($spacing-unit);
        }
    }
    &--craftVerification {
        grid-area: craftVerification;
    }
}

.Footer-bottom {
    @include font-size(12, false);
    padding-top: u($spacing-unit * 1.6);
    padding-bottom: u($spacing-unit * 1.6);

    @include mq($until: $viewport--sm - 1) {
        >.Nav {
            margin-top: u($spacing-unit--xs);
        }
    }

    @include mq($from: $viewport--sm) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: u($spacing-unit * -1);

        >p,
        >.Nav {
            margin-left: u($spacing-unit);
        }
    }
}
