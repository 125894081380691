/**
 * Elements: Heading
 */

// Namespace
$heading-namespace:                   $namespace !default;

// Typography
$heading-font-stack:                  $font-poppins !default;

// Sizes
$heading-size-giga:                   85 !default;
$heading-size-mega:                   72 !default;
$heading-size-kilo:                   48 !default;

$heading-size-1:                      40 !default;
$heading-size-2:                      22 !default;
$heading-size-3:                      20 !default;
$heading-size-4:                      18 !default;
$heading-size-5:                      16 !default;
$heading-size-6:                      14 !default;

$heading-size-milli:                  12 !default;
$heading-size-micro:                  10 !default;

// Spacing
$heading-spacing-giga:                false !default;
$heading-spacing-mega:                false !default;
$heading-spacing-kilo:                false !default;

$heading-spacing-1:                   false !default;
$heading-spacing-2:                   false !default;
$heading-spacing-3:                   false !default;
$heading-spacing-4:                   false !default;
$heading-spacing-5:                   false !default;
$heading-spacing-6:                   false !default;

$heading-spacing-milli:               false !default;
$heading-spacing-micro:               false !default;
