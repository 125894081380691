// Form
.Block--contact {

    .Logo--contact {
        margin-bottom: u($spacing-unit);
        height: 67px;
        width: 273px;
    }

    @include mq($from: $viewport--md, $until: $viewport--lg - 1) {
        .Logo--contact {
            @include grid-area(2, 5, 1, 2);
            margin-top: u($spacing-unit--lg);
        }

        .Contact-body {
            @include grid-area(2, 5, 2, 3);
        }
    }

    @include mq($from: $viewport--lg) {
        .Logo--contact {
            @include grid-area(2, 3, 2, 3);
        }

        .Contact-body {
            @include grid-area(3, 5, 2, 3);
        }
    }

    .Contact-body {

        a {
            color: currentColor;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .Contact-object {

        .is-ie &:last-of-type {
            margin-left: u($spacing-unit--lg);
            max-height: u(370);
        }

        &, img {
            width: 100%;
        }

        @include mq($until: $viewport--md - 1) {
            margin-top: u($spacing-unit--sm);
        }
    }

    .Contact-maps {
        @include grid-area(1, 3, 3, 7);

        > .FlexEmbed {
            max-height: u(468px);
            height: 100%;

            @include mq($from: $viewport--md, $until: $structure-responsive-breakpoint - 1) {
                margin-left: u($structure-gutter--l * -1);
                width: calc(100% + #{u($structure-gutter--l)});
            }

            @include mq($from: $structure-responsive-breakpoint, $until: $structure-width--xl + 199) {
                margin-left: calc((100vw - 1230px) / -2);
                width: calc(100% + (100vw - 1230px) / 2);
            }

            @include mq($from: $structure-width--xl + 200) {
                margin-left: -5vw;
            }

            @include mq($until: $viewport--md - 1) {
                &::before {
                    padding-bottom: 100% !important;
                }
            }
        }
    }

    .Contact-object {
        @include grid-area(3, 4, 4, 6);
        align-self: end;
    }
}
