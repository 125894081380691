// Member
.Member {
    @include grid(100%, 100%);

    .Member-object {
        @include grid-area(1, 2, 1, 2);
    }

    .Member-body {
        // @include font-size(25, false);
        @include fluid-type(font-size, $viewport--md, $viewport--xl, 18, 22);
        @include grid-area(1, 2, 1, 2);
        @include grid(100%, 1fr auto);
        @include transition(#{opacity, visibility}, 200ms);
        background-color: rgba($black, .675);
        color: $white;
        font-weight: 300;
        padding: u($spacing-unit--lg);

        .Button {
            @include grid-area(1, 2, 2, 3);
            color: $white;
            justify-self: center;
        }
    }

    .Member-bodyText {
        align-self: center;
        justify-self: center;
        text-align: center;
        width: 100%;
    }

    .Member-title {
        font-size: inherit;
        color: $white;
        font-weight: 700;
        margin-bottom: 0;
    }
}

.Member-body {

    .Member:not(:hover) & {
        opacity: 0;
        visibility: hidden;
    }
}
