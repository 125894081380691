.Filters {
    display: flex;
    flex-wrap: wrap;
    margin-top: u(-20);
    margin-left: u(-20);

    > .Filter {
        padding-top: u(20);
        padding-left: u(20);
        flex-basis: auto;
    }
}

.Filter {
    width: 100%;

    @include mq($from: $viewport--sm) {
        max-width: u(280);
    }

    .Form-field--select {
        @include background-svg('<svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.5307 1.47236C11.7533 1.25388 11.7533 0.891865 11.5307 0.665764C11.3132 0.447919 10.9503 0.447919 10.7353 0.665764L6.66548 4.75464C6.66548 4.75464 6.3807 5.05173 6.09763 5.05173C5.81457 5.05173 5.53184 4.75464 5.53184 4.75464L1.46894 0.665764C1.24449 0.447919 0.8841 0.447919 0.665971 0.665764C0.441519 0.891865 0.441519 1.25388 0.665971 1.47236L5.69939 6.52852C5.91752 6.75399 6.2798 6.75399 6.49477 6.52852L11.5307 1.47236Z" fill="#{rgba($cc-black, 0.5)}"/></svg>');
        background-size: u(12, 7);
        background-position: right u(20) center;
        border: px(1) solid;
        color: $cc-grey--alt;
        font-size: u(13);
        font-weight: 300;
        line-height: (18 / 13);
        padding: u(12, 20);
        padding-right: u(20 + 12 + 20);
        letter-spacing: px(0.3);
    }
}
