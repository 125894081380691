.Search {
    @include font-size(14, false);
    background-color: $white;
    display: flex;

    &-input {
        border-bottom: 0;
        flex-grow: 1;
        font-weight: 300;
        padding: u(11, 20);
    }

    &-button {
        @include position(relative, $z: 1);
        cursor: pointer;
        flex-shrink: 0;
        padding-right: u(20);
        padding-left: u(20);
        margin-left: u(-20);
    }
}
