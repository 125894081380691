.newCases {

    @include mq($from: $viewport--sm) {
        display: flex;
        margin-top: u(-30);
        margin-left: u(-30);

        .Block--cases & {
            flex-wrap: wrap;
        }
    }

    @include mq($from: $viewport--lg) {
        margin-top: u(-50);
        margin-left: u(-45);

        @include mq($from: 1800) {
            margin-left: u(-50);
        }
    }

    > .newCase {

        @include mq($from: $viewport--sm) {
            padding-top: u(30);
            padding-left: u(30);
            width: (100% / 2);
        }

        @include mq($from: $viewport--md) {
            width: (100% / 3);
        }

        @include mq($from: $viewport--lg) {
            padding-top: u(50);
            padding-left: u(45);

            @include mq($from: 1800) {
                padding-left: u(50);
            }
        }

        @include mq($from: $viewport--md) {

            &--span {
                width: (100% / 3 * 2);
            }
        }
    }
}

.newCase {
    @include position(relative, $z: 0);
    flex-basis: auto;
    min-width: 0;

    p {
        opacity: 0.5;
    }

    & + & {

        @include mq($until: $viewport--sm - 1) {
            margin-top: u(40);
        }
    }

    &.newCase--span {

        @include mq($from: $viewport--md) {
            width: (100% / 3 * 2);
        }
    }
}

.newCase-object {
    display: block;

    .newCase--span &[data-ratio="1:1"]::before {

        @include mq($from: $viewport--md) {
            padding-bottom: calc(50% - 15px);
        }

        @include mq($from: $viewport--lg) {
            padding-bottom: calc(50% - 22.5px);

            @include mq($from: 1800) {
                padding-bottom: calc(50% - 25px);
            }
        }
    }

    @include hover() {
        background-color: $cc-black;
        overflow: hidden;

        .newCase:hover & {

            .newCase-image {
                opacity: 0.8;
                transform: scale(1.1);
            }
        }
    }
}

.newCase-image {
    object-fit: cover;
    object-position: var(--pos);
    height: 100%;

    @include hover() {
        @include transition(#{opacity, transform}, 200ms);
    }
}

.newCase-body {
    margin-top: u(20);

    @include mq($from: $viewport--sm) {
        margin-top: u(30);
    }

    :last-child {
        margin-bottom: 0;
    }
}

.newCase-title {
    color: $cc-black;
    font-size: u(17);
    line-height: (26 / 17);
    letter-spacing: px(1);
    margin-bottom: u(2);

    .Block--black & {
        color: currentColor;
    }
}

.newCase-link {
    @include position(absolute, 0, 0, 0, 0, 1);

    @include mq($from: $viewport--sm) {
        top: u(30);
        left: u(30);
    }

    @include mq($from: $viewport--lg) {
        top: u(50);
        left: u(45);

        @include mq($from: 1800) {
            left: u(50);
        }
    }
}
