.Block--service {
    overflow: hidden;
}

.Block--services {
    @include fluid-type(padding-bottom, $viewport--sm, $viewport--lg, 60, 120);
    @include position(relative, $z: 0);

    @include mq ($from: $viewport--md) {
        overflow-x: hidden;
    }

    .Services {

        &-header {
            margin-bottom: u(50);
        }

        &-items {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            margin-top: u(-40);
            margin-left: u(-30);

            &::after {
                @include position(absolute, 50%, 50%, 0, 50%, -1);
                @include fluid-type(bottom, $viewport--sm, $viewport--lg, -60, -120);
                background-color: $cc-grey--light;
                content: '';
                transform: translateX(-50%);
                width: 100%;

                @include mq ($from: $viewport--md) {
                    width: 100vw;
                }
            }
            &[data-columns="1"]::after {

                @include mq($until: $viewport--xs - 1) {
                    @include fluid-type(top, $viewport--sm, $viewport--lg, -60, -120);
                }
            }

            &[data-columns="2"]::after {

                @include mq($until: $viewport--sm - 1) {
                    @include fluid-type(top, $viewport--sm, $viewport--lg, -60, -120);
                }
            }

            &[data-columns="3"]::after {

                @include mq($until: $viewport--md - 1) {
                    @include fluid-type(top, $viewport--sm, $viewport--lg, -60, -120);
                }
            }

            &[data-columns="4"]::after {

                @include mq($until: $viewport--xl - 1) {
                    @include fluid-type(top, $viewport--sm, $viewport--lg, -60, -120);
                }
            }
        }
    }

    .Service {
        box-sizing: inherit;
        flex-basis: 100%;
        min-width: 0;
        padding-top: u(40);
        padding-left: u(30);

        &.is-secondary {

            opacity: 0;
            
            .Service-body {
                width: 100%;
                text-align: left;
                margin-top: 0;
                align-items: flex-start;
            }

            .Service-title {
                padding: u(40, 0, 0, 0);
                margin-bottom: u(10);
            }
        }

        &,
        &-body {
            display: flex;
            flex-direction: column;
        }

        &-title {
            color: $cc-cyan;
            letter-spacing: px(0.82);
            font-weight: 500;
            padding: u(20, 10);
            line-height: 1.34;
            margin-bottom: u(10);
        }

        &-body {
            @include position(relative, $z: 1);
            background-color: $cc-grey--light;
            align-items: center;
            flex-grow: 1;
            margin-top: u(-64);
            margin-right: auto;
            margin-left: auto;
            padding-right: u(10);
            padding-left: u(10);
            text-align: center;
            width: 85%;

            @include mq($from: 1800) {
                width: 65%;
            }

            .Button {
                margin-top: auto;
            }

            p {
                margin-bottom: u(15);
            }
        }

        &-object,
        &-image {
            @include dimensions(100%);
            display: block;
        }
    }
}
