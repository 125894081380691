// Content
.Block--content {
    background-color: $cc-grey--light;
    padding-top: u($spacing-unit--lg);
    padding-bottom: u($spacing-unit--lg);

    @include mq($from: $viewport--md) {
        padding-top: u(130px);
        padding-bottom: u(130px);
    }

    .Block + & {
        margin-top: u($spacing-unit--lg);

        @include mq($from: $viewport--md) {
            margin-top: u(120px);
        }
    }

    .Alfa {
        margin-top: u(40px);
        margin-bottom: u(10px);
    }

    h2:not(.Alfa) {
        margin-top: u($spacing-unit--lg);
        margin-bottom: u($spacing-unit--xs);
    }

    .Bravo {
        margin-bottom: u($spacing-unit--xs);
    }

    .Button {

        body:not(.BlogDetailPage) & {
            margin-top: u($spacing-unit--lg);
        }
    }

    .Media-object {

        &.Media-object--right {
            align-self: end;
        }
        &.Media-object--left {
            align-self: start;

            @include mq($until: $viewport--lg - 1) {
                margin-right: u(-40px);
                margin-bottom: u($spacing-unit);
                margin-left: u(-40px);
            }

            @include mq($from: $viewport--lg, $until: 1190px - 1) {
                margin-left: u($structure-gutter--l * -1);
                width: calc(100% + #{u($structure-gutter--l)});
            }

            @include mq($from: 1190px) {
                margin-left: calc((100vw - 1110px) / -2);
                width: calc(100% + (100vw - 1110px) / 2);
            }
        }

        &.Media-object--middle {
            margin-top: u($spacing-unit);
            margin-bottom: u($spacing-unit);

            &:not(.has-maxWidth) {
                margin-right: u(-40px);
                margin-left: u(-40px);

                @include mq($from: $viewport--md) {
                    margin-right: u($spacing-unit * -3);
                    margin-left: u($spacing-unit * -3);
                }
            }

            @include mq($from: $viewport--md) {
                margin-top: u($spacing-unit--lg);
                margin-bottom: u($spacing-unit--lg);
            }

            + .Alfa {
                margin-top: u(20px);
            }
        }

        .Slider-button {

            &--prev {
                left: u($spacing-unit--sm);
            }

            &--next {
                right: u($spacing-unit--sm);
            }
        }
    }

    .Table {
        border-collapse: collapse;
        padding: 0;
        word-break: break-word;
        width: auto;

        th {
            background-color: $cc-cyan;
            color: $white;
            font-weight: 500;
        }

        @include mq($from: 840) {
            margin-right: u($spacing-unit * -3);
            margin-left: u($spacing-unit * -3);
        }
    }
}
