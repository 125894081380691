// Banner
.Block--banner {

    + .Block {
        margin-top: u(-90px);
    }

    > .Container {
        @include grid(100%, 1fr auto 90px auto);

        &::before {
            content: '';
            position: absolute;
            bottom: -2px;
            width: 100%;
            height: 4px;
            background-color: $white;
        }

        &::after {
            @include grid-area(1, 2, 3, 5);
            background-color: $white;
            content: '';
            display: block;
            height: 90px;
            // max-width: u($structure-width--lg);
            justify-self: center;
            width: 100%;

            // Responsive
            @include mq($from: $structure-width--xl + 200) {
                max-width: u($structure-width--lg);
            }

            @include mq($from: $viewport--md, $until: $structure-width--xl + 200 - 1) {
                max-width: u($structure-width--sm);
            }
        }
    }

    .Banner-object {
        @include position(relative, $z: -1);
        @include grid-area(1, 2, 1, 4);
        display: block;
        user-select: none;
        pointer-events: none;

        &::after {
            @include position(absolute, 0, 0, 0, 0, 1);
            background-color: rgba($cc-black, .15);
            content: '';
        }

        @include mq($from: $viewport--md, $until: $structure-width - 1) {
            margin-right: u($structure-gutter--r * -1);
            margin-left: u($structure-gutter--l * -1);
        }

        @include mq($until: $viewport--md - 1) {
            margin-right: u($structure-responsive-gutter--r * -1);
            margin-left: u($structure-responsive-gutter--l * -1);
        }

        img {
            min-height: u(300px);
            object-fit: cover;
            width: 100%;
        }
    }

    .Page-title {
        @include grid-area(1, 2, 1, 2);
        align-self: center;
        justify-self: center;
    }

    .Mouse {
        @include grid-area(1, 2, 2, 3);
        justify-self: center;
    }
}
