/* Mouse */
.Mouse {
    align-items: center;
    border: 1px solid $white;
    border-radius: u(9px);
    display: flex;
    flex-flow: column nowrap;
    height: u(32px);
    padding: u(5px, 0);
    width: u(18px);

    &::before {
        background-color: $white;
        border-radius: 100%;
        content: '';
        display: block;
        height: u(4px);
        width: u(4px);
        animation-name: scroll;
        animation-duration: 1.5s;
        animation-iteration-count: infinite
    }
}

@keyframes scroll {
    0% {
        opacity: 0
    }

    10% {
        transform: translateY(0);
        opacity: 1
    }

    100% {
        transform: translateY(10px);
        opacity: 0
    }
}
