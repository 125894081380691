.Block--short {

    .Block + &,
    & + .Block {
        margin-top: u(60);

        @include mq($from: $viewport--md) {
            margin-top: u(120);
        }
    }

    .Icons {
        display: flex;
        justify-content: space-around;

        @include mq($until: $viewport--md - 1) {
            flex-direction: column;
            align-items: center;
        }

        > .Icons-item {
            display: flex;
            flex-direction: column;
            padding: u(15);
            width: 100%;

            @include mq($from: $viewport--md) {
                width: (100% / 3);
            }
        }

        &-body {

            :last-child {
                margin-bottom: 0;
            }
        }

        &-number {
            @include fluid-type(font-size, $viewport--sm, $viewport--xl, 24, 33);
            color: $cc-black;
            font-weight: 600;
            margin-top: u(30);
            margin-bottom: u(10);
            line-height: 1;
        }

        &-object {
            color: $cc-cyan;
            display: block;
            margin-right: auto;
            margin-left: auto;
        }
    }
}

.Short {

    &-title {
        @include fluid-type(font-size, $viewport--sm, $viewport--lg, 32, 40);
        font-weight: 600;
        letter-spacing: normal;
        line-height: 1.25;
        margin-right: auto;
        margin-left: auto;
        max-width: u(900);
        text-align: center;

        &:not(:only-child) {
            margin-bottom: u(30);

            @include mq($from: $viewport--lg) {
                margin-bottom: u(45);
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-body {
        text-align: center;
    }
}
