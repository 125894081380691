// Related
.Block--related {

    .Grid-cell {
        display: flex;
        flex-direction: column;
    }

    a.Related-item {
        @include transition(#{background-color, color}, 200ms);
        background-color: $cc-grey--light;
        color: currentColor;
        text-decoration: none;
        padding: u(35px, $spacing-unit--lg);
        flex-grow: 1;

        &:hover {
            background-color: $cc-black;
            color: $white;
        }
    }
}
