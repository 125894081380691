.Block--faqHeader {
    color: $white;
    overflow: hidden;

    @include mq($until: $viewport--md - 1) {
        background-color: $cc-black;
    }
}

.Block--faqContent {

    + .Block--cta {
        @include fluid-type(margin-top, $viewport--sm, $viewport--lg, 60, 85);
    }
}

.faqHeader {

    @include mq($from: $viewport--md) {
        @include grid(1fr 1fr, 1fr 0.65fr);

        &::before {
            @include position(relative, $right: 50%, $left: 50%, $z: -1);
            @include grid-area(1, 3, 1, 2);
            background-color: $cc-black;
            content: '';
            display: block;
            margin-left: -50vw;
            margin-right: -50vw;
            width: 100vw;
        }
    }

    &-body {

        @include mq($from: $viewport--md) {
            @include grid-area(1, 2, 1, 2);
            align-self: center;
            margin-right: u(60);
            padding-top: u(60);
            padding-bottom: u(80);

            :last-child {
                margin-bottom: 0;
            }
        }
    }

    &-object {

        @include mq($from: $structure-responsive-breakpoint) {
            margin-right: u(-60);
        }

        @include mq($from: $viewport--md) {
            @include grid-area(2, 3, 1, 3);
            align-self: end;
        }
    }

    &-image {
        @include dimensions(100%);
    }

    &-title {
        max-width: u(400);
    }
}

.faqContent {

    &-header {
        max-width: u(480);
        margin-bottom: u(40);
    }
}

.Questions {
    display: flex;
    flex-flow: row wrap;
    counter-reset: i;
    margin-bottom: px(-1);
    margin-right: px(-1);
}

.Question {
    @include transition(#{background-color, color});
    @include position(relative, $z: 0);
    counter-increment: i;
    font-weight: 500;
    line-height: 1.67;
    padding: u($spacing-unit);

    @include mq($from: $viewport--md) {
        border-right: px(1) solid $cc-grey--border;
        border-bottom: px(1) solid $cc-grey--border;
        padding: u(35, 45);
    }

    @include mq($from: $viewport--lg) {
        width: calc(100% / 3);
    }

    @include mq($from: $viewport--md, $until: $viewport--lg - 1) {
        width: calc(100% / 2);
    }

    @include mq($until: $viewport--md - 1) {
        width: 100%;
    }

    &,
    &:active,
    &:focus,
    &:hover {
        color: currentColor;
        border-bottom: px(1) solid $cc-grey--border;
        text-decoration: none;
    }

    &:hover {
        background-color: $cc-black;
        color: $white;
    }

    &::before {
        color: $cc-cyan;
        content: counter(i, decimal-leading-zero) ".";
        display: block;
        font-weight: 600;
        margin-bottom: u(15);
    }
}
