// Development
.Block--development {

    > .Container {

        @include mq($from: $structure-responsive-breakpoint) {
            @include grid(50px 40% 360px 1fr 50px, 110px auto 40px auto 100px auto);
            grid-column-gap: u($spacing-unit--lg);
        }

        @include mq($from: $viewport--md, $until: $viewport--lg - 1) {
            @include grid(50px 40% 360px 1fr, auto auto 40px auto 100px auto);
            grid-column-gap: u($spacing-unit);
        }

        @include mq($from: $viewport--lg, $until: $structure-responsive-breakpoint - 1) {
            @include grid(50px 40% 360px 1fr, 110px auto 40px auto 100px auto);
            grid-column-gap: u($spacing-unit);
        }

        @include mq($from: $viewport--md, $until: $viewport--lg - 1) {

            .Service-title {
                @include grid-area(2, 5, 1, 2);
                margin-top: u($spacing-unit--lg);
            }

            .Service-body {
                @include grid-area(2, 5, 2, 3);
            }
        }

        @include mq($from: $viewport--lg) {
            .Service-title {
                @include grid-area(2, 3, 2, 3);
            }

            .Service-body {
                @include grid-area(3, 5, 2, 3);
            }
        }

        @include mq($from: $viewport--md) {

            &::before {
                @include grid-area(1, 6, 1, 5);
                background-color: $cc-grey--light;
                content: '';
                display: block;
                width: calc(100% + #{u($structure-responsive-gutter--l)});

                @include mq($from: $viewport--md, $until: $structure-responsive-breakpoint - 1) {
                    width: calc(100% + #{u($structure-gutter--l)});
                }

                @include mq($from: $structure-responsive-breakpoint) {
                    width: calc(100% + ((100vw - #{u($structure-width)}) / 2));
                }
            }
        }
    }

    .Service-object {

        .is-ie &:last-of-type {
            margin-left: u($spacing-unit--lg);
            max-height: u(370);
        }

        img {
            @include dimensions(100%);
            object-fit: cover;
        }

        @include mq($until: $viewport--md - 1) {
            width: 100%;

            &:nth-of-type(1) {
                margin-bottom: u($spacing-unit--sm);
            }
        }

        &:nth-of-type(1) {
            @include grid-area(1, 3, 3, 7);
            align-self: end;

            &.object-fit-polyfill {
                height: u(468px);
                margin-right: u($spacing-unit);
            }

            img {
                max-height: 468px;
            }

            @include mq($from: $viewport--md, $until: $structure-responsive-breakpoint - 1) {
                margin-left: u($structure-gutter--l * -1);
                width: calc(100% + #{u($structure-gutter--l)});
            }

            @include mq($from: $structure-responsive-breakpoint, $until: $structure-width--xl + 199) {
                margin-left: calc((100vw - 1230px) / -2);
                width: calc(100% + (100vw - 1230px) / 2);
            }

            @include mq($from: $structure-width--xl + 200) {
                margin-left: -5vw;
            }
        }

        &:nth-of-type(2) {
            @include grid-area(3, 4, 4, 6);
        }
    }

    .Label {
        @include grid-area(3, 4, 6, 7);
        margin-top: u(20px);
    }
}
