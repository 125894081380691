// Fancybox
.fancybox-bg {
    background-color: rgba($black, .9);
}

.fancybox-button--arrow_left,
.fancybox-button--arrow_right {
    padding: 0 !important;
    width: 50px !important;
    height: 50px !important;
    background-repeat: no-repeat !important;

    &[disabled] {
        opacity: 0.3 !important;
    }

    svg {
        display: none;
    }
}

.fancybox-button--arrow_left {
    @include background-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 49"><title>arrow-left</title><rect width="48" height="49" fill="#3650dc"/><path d="M20.92,30.79a.76.76,0,0,0,1,0,.67.67,0,0,0,0-1l-5.34-5.09H34.26A.72.72,0,0,0,35,24a.73.73,0,0,0-.74-.72H16.63L22,18.21a.68.68,0,0,0,0-1,.76.76,0,0,0-1,0L14.31,23.5a.68.68,0,0,0,0,1Z" fill="#fff" fill-rule="evenodd"/></svg>');
}

.fancybox-button--arrow_right {
    @include background-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 49"><title>arrow-right</title><rect width="48" height="49" fill="#3650dc"/><path d="M27.08,18.21a.76.76,0,0,0-1,0,.67.67,0,0,0,0,1l5.34,5.09H13.74A.72.72,0,0,0,13,25a.73.73,0,0,0,.74.72H31.37L26,30.79a.68.68,0,0,0,0,1,.76.76,0,0,0,1,0l6.61-6.29a.68.68,0,0,0,0-1Z" fill="#fff" fill-rule="evenodd"/></svg>');
}

.popUp {
    max-width: u(886);

    + .fancybox-close-small {
        color: $cc-cyan;
        height: u(60);
        padding: u(10);
        width: u(60);
    }

    .Media-object,
    .MediaItem-object {
        display: flex;
        flex-direction: column;
    }

    .MediaItem-object,
    .MediaItem-image {
        flex-grow: 1;
    }

    .Container--Form {
        align-self: center;

        @include mq($from: $viewport--md) {
            width: (100% - (100% / 886 * 421));
        }

        @include mq($from: $viewport--md, $until: $viewport--lg - 1) {
            padding: u($spacing-unit--lg);
        }

        @include mq($from: $viewport--lg) {
            padding: u($spacing-unit--lg, 90, $spacing-unit--lg, 75);
        }
    }

    display: flex;
    // align-items: center;

    .Media-object {
        // margin-right: u(45);
        min-height: u(600);

        @include mq($until: $viewport--md - 1) {
            display: none;
        }

        @include mq($from: $viewport--md) {
            width: (100% / 886 * 421);
        }

        img {
            object-fit: cover;
        }
    }

    p {
        font-family: Poppins;
        font-size: 23px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.61;
        letter-spacing: normal;
        color: $cc-cyan;
        margin-bottom: u($spacing-unit--sm);
        max-width: u(500);
    }

    .Form {
        // width: 80%;
        margin: 0;

        &-item--list {
            @include font-size(12, false);
            color: #acacac;
            line-height: (14 / 12);
            margin-top: u($spacing-unit);
        }

        &-item--action {
            margin-top: u($spacing-unit);
        }

        label {
            font-weight: 300 !important;
        }

        input {
            border: none;
            border-bottom: 1px solid grey;
        }

        label:nth-last-child(1) {
            color: grey;
            opacity: 0.8;
            font-size: 12px;
        }
    }
}

#pop-up {
    padding: 0 !important;
}

@include mq($until: 1029px) {

    .popUp p {
        width: 100%
    }
}

@include mq($until: $viewport--md - 1) {

    .popUp {
        flex-direction: column;
        padding: u(30);

        .Media-object {
            margin-right: 0
        }
    }
}
