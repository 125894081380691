.Block--error {
    margin: 3rem auto 0 !important;
    text-align: center;
    .Error-title {
        font-size: u(70px);
        font-weight: 700;
        margin: 0;
        text-align: center;
        @include mq($from: $viewport--sm) {
            font-size: u(100px);
        }
        @include mq($from: $viewport--md) {
            font-size: u(140px);
        }
    }
    .Error-subtitle {
        margin: 0;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 2px;
        text-align: center;
    }
    .Error-text {
        margin: u(10px) 0 u(30px);
        text-align: center;
    }
    .Error-link {
        margin-top: u(15px);
        color: #000;
        text-align: center;
    }
}