.Block--heading {
    padding-top: u(30);
    overflow: hidden;

    &.Block--faq.has-grid {

        @include mq($until: $viewport--lg - 1) {
            background-color: $cc-grey--light;
        }

        > .Container {
            @include fluid-type(padding-bottom, $viewport--sm, $viewport--lg, 60, 120);
            grid-row-gap: 0;

            &::before {
                @include fluid-type(margin-bottom, $viewport--sm, $viewport--lg, -60, -120);
            }
        }
    }

    @include mq($from: $viewport--lg) {
        padding-top: u(125);
    }

    > .Container {

        @include mq($from: $viewport--md, $until: $viewport--lg - 1) {
            max-width: u(740 + 40 + 40);
        }

        @include mq($from: $viewport--lg) {
            padding-right: u(60);
            padding-left: u(60);

            @include parent-nth-status(1, '.has-grid', suffix) {
                grid-template-columns: 1fr 1fr;
                grid-column-gap: u(45);
                grid-row-gap: u(70);
            }
        }

        &::before {
            @include grid-area(1, 3, 1, 3);
            background-color: $cc-grey--light;
            content: '';
            margin: u(-125) auto u(240) u(-80);
            width: calc(100% + 80px + 80px + ((100vw - #{u(1230)}) / 2));

            @include mq($until: $viewport--lg - 1) {
                content: none;
            }

            @include mq($from: $viewport--lg, $until: 1300) {
                width: 100vw;
            }
        }
    }

    .Heading-header {
        @include grid-area(1, 2, 1, 2);
        align-self: start;
        max-width: u(400);
        width: 100%;

        @include mq($until: $viewport--lg - 1) {
            margin-bottom: u($spacing-unit);
        }

        @include mq($from: $viewport--md, $until: $viewport--lg - 1) {
            max-width: none;
        }
    }

    .Heading-title {
        @include fluid-type(font-size, $viewport--sm, $viewport--lg, 32, 40);
        font-weight: 600;
        line-height: 1.25;
    }

    .Heading-body {
        @include grid-area(2, 3, 1, 2);
        max-width: u(500);
        width: 100%;

        .is-ie & {
            margin-left: u(45);
            margin-bottom: u(70);
        }

        @include mq($from: $viewport--md, $until: $viewport--lg - 1) {
            max-width: none;
        }

        @include mq($from: $viewport--lg) {
            padding-left: u(20);
        }
    }

    .Media-object {
        @include grid-area(1, 2, 2, 3);
        height: u(460);

        @include mq($from: $viewport--md, $until: 1200px - 1) {
            margin-left: u($structure-gutter--l * -2);
            width: calc(100% + #{u($structure-gutter--l * 2)});
        }

        @include mq($from: 1200px, $until: $structure-width--xl + 199) {
            margin-left: calc((100vw - 1080px) / -2);
            width: calc(100% + (100vw - 1080px) / 2);
        }

        @include mq($from: $structure-width--xl + 200) {
            margin-left: calc(-5vw - 80px);
        }
    }

    .Heading-side {
        @include grid-area(2, 3, 2, 3);
        align-self: end;
        line-height: u(36);
        max-width: u(416);
        width: 100%;

        .is-ie & {
            margin-left: u(45);
        }

        @include mq($from: $viewport--md, $until: $viewport--lg - 1) {
            max-width: none;
        }

        &Body {
            background-color: $cc-black;
            color: $white;
            padding: u(45, 36);
            max-width: u(380);
            width: 100%;

            a:not([class]) {

                &,
                &:active,
                &:focus,
                &:hover {
                    color: currentColor;
                }
            }

            @include mq($from: $viewport--md, $until: $viewport--lg - 1) {
                max-width: none;
            }

            @include mq($until: $viewport--lg - 1) {
                padding: u($spacing-unit);
            }
        }

        ul:not([class]) {
            list-style: none;
            padding-left: u(38);
            line-height: 1.73;

            li {
                margin-bottom: u(12);

                &:before {
                    background-image: url('#{$path-img}bullet.svg');
                    background-repeat: no-repeat;
                    background-size: 17px 17px;
                    display: inline-block;
                    content: '';
                    height: u(16);
                    mask-size: cover;
                    margin-top: u(-4.5);
                    margin-left: u(-38);
                    margin-right: u(21);
                    vertical-align: middle;
                    width: u(17);
                }
            }
        }

        .Label {
            margin-top: u(20);
            max-width: u(375);
            width: 100%;
        }
    }

    @include mq($until: $viewport--lg - 1) {

        .Heading-title,
        .Heading-body {
            margin-left: 0;
            width: 100%;
        }

        .Heading-side {
            height: auto;
            margin-bottom: u(30);
            margin-top: u(30);
            width: 100%;
        }

        .Media-object {
            margin-left: 0;
            width: auto;
        }
    }
}
