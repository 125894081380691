/* Footer */
.Nav--extraNav {

    @include mq($from: $viewport--sm + 100) {
        margin: u(0, 10);
    }

    .Footer-subtitle {
        letter-spacing: normal;
    }

    .Nav-list {
        flex-direction: column;
    }

    .Nav-item {
        width: auto;

        &:hover {
            .Nav-link {
                transform: translateX(27px);
            }

            .Nav-link--icon {
                opacity: 1;
                left: -27px;
            }
        }
    }

    .Nav-link {
        white-space: nowrap;
        position: relative;
        @include transition(transform, .4s);

        &--icon {
            @include dimensions(16, 11);
            position: absolute;
            top: calc(50% - (11px / 2));
            left: -38px;
            opacity: 0;
            transition: opacity .4s, left .4s;
        }

        &:hover {
            text-decoration: none !important;
        }
    }
}
