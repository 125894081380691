// Case
.Block--case {

    &.Block--bgGrey {
        background-color: $cc-grey--light;
        padding-top: u($spacing-unit--lg);
        padding-bottom: u($spacing-unit--lg);

        @include mq($from: $viewport--lg) {
            padding-top: u($spacing-unit * 5.8);
            padding-bottom: u($spacing-unit * 5.8);
        }
        + .Block--bgGrey {
            margin-top: 0;
            padding-top: 0;
        }
    }

    &.Block--text {


        .Case-object {

            + .Case-body {
                margin-top: u($spacing-unit--lg);

                @include mq($from: $viewport--lg) {
                    margin-top: u($spacing-unit * 5.8);
                }
            }
        }

        .Case-body {
            padding-right: u($spacing-unit);
            padding-left: u($spacing-unit);
            margin-left: auto;
            margin-right: auto;

            @include mq($from: $viewport--lg) {
                padding-right: u($spacing-unit--lg);
                padding-left: u($spacing-unit--lg);
            }
        }

        .Nav--colors,
        .Button {
            margin-top: u($spacing-unit * 3);
        }
    }

    &.Block--image {

        .Case-body {
            max-width: u(740px);
            margin-right: auto;
            margin-left: auto;
        }
        .Case-object + .Case-body {
            margin-top: u($spacing-unit--lg);

            @include mq($from: $viewport--lg) {
                margin-top: u($spacing-unit * 5.8);
            }
        }
    }

    .Case-body {
        &.Case-body--left {
            text-align: left;
        }
        &.Case-body--center {
            text-align: center;
        }
        &.Case-body--right {
            text-align: right;
        }
        &.Case-body--large {
            max-width: 100rem;
        }
        &.Case-body--medium {
            max-width: 75rem;
        }
        &.Case-body--small {
            max-width: 50rem;
        }
        .Text {
            &.Text--column {
                column-count: 2;
                column-gap: 50px;
            }
        }
    }
    .Grid--imageLeft, .Grid--imageRight {
        .Case-object {
            @include mq($until: $viewport--lg) {
                margin-bottom: 3.3333rem;
            }
        }
    }
    .Grid--imageLeft {
        flex-flow: row wrap;
    }
    .Grid--imageRight {
        flex-flow: row-reverse wrap;
    }
    .Case-object {

        &.Case-object--offsetTop {
            @include mq($from: $viewport--lg) {
                transform: translateY(u(-80px));
            }
        }
        &.Case-object--offsetBottom {
            @include mq($from: $viewport--lg) {
                transform: translateY(u(80px));
            }
        }
    }
    .Case-object--fs {

        .CaseItem-object {
            width: 100%;
        }

        img {
            // max-height: 736px;
            object-fit: cover;
            width: 100%;
        }
    }
}
