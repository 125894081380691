/* Mixin: Dimensions */
@mixin dimensions($width: null, $height: $width) {
    @if not is-measure($height) and str-slice(quote($height), 1, 4) != 'calc' {
        $height: u($height);
    }

    @if not is-measure($width) and str-slice(quote($width), 1, 4) != 'calc' {
        $width: u($width);
    }

    height: $height;
    width: $width;
}
