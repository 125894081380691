.Preloader {
    @include position(fixed, 0, 0, 0, 0, 99);
    background-color: $black;
    color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    svg {
        fill: $white;
        display: block;
        height: 130px;
        width: 105px;

        // animation-name: pulse;
        // animation-iteration-count: infinite;
        // animation-duration: 2s;
        // animation-fill-mode: both;
    }
}
