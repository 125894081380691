// Optimalisation
.Block--optimalisation {

    > .Container {
        @include mq($from: $viewport--md) {
            @include grid(100px 40% 50px 1fr 100px, auto auto 90px);
        }
    }

    .Service-title {
        @include grid-area(4, 6, 1, 2);
    }

    .Service-body {
        @include grid-area(2, 3, 1, 3);
    }

    .Service-object {
        @include grid-area(4, 6, 2, 4);
        align-self: end;

        &.object-fit-polyfill {
            height: u(468px);

            + .Label {
                display: none;
            }
        }

        &, img {
            max-height: u(468px);
        }

        img {
            @include dimensions(100%);
            object-fit: cover;
        }

        @include mq($from: $viewport--md, $until: $structure-responsive-breakpoint - 1) {
            margin-right: u($structure-gutter--l * -1);
            width: calc(100% + #{u($structure-gutter--l)});
        }

        @include mq($from: $structure-responsive-breakpoint, $until: $structure-width--xl - 200) {
            margin-right: calc((100vw - 1230px) / -2);
            width: calc(100% + (100vw - 1230px) / 2);
        }

        @include mq($from: $structure-width--xl + 200) {
            margin-right: -5vw;
        }
    }

    .Label {
        @include grid-area(3, 4, 2, 4);
        display: inline-flex;
        align-self: end;
        justify-self: center;
        // position: static;
    }
}
