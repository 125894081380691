/* General */

.Main {
    width: 100%;
}

// LazySizes
.LazyLoad,
.LazyLoading {
    opacity: 0;
}

.LazyLoaded {
    @include transition(opacity, 300ms);
    opacity: 1;
}

// Tabbing
body:not(.is-tabbing) {

    span:focus,
    button:focus,
    input:focus,
    select:focus,
    textarea:focus {
        outline: none;
    }
}

// Strong
strong,
b,
dt {
    font-weight: 600;
}

// Video
video {
    width: 100%;
}

// Placeholder
.has-placeholder {
    background-color: #f0f0f0;
    background-image: url(#{$path-img}logo.svg);
    background-size: 50%;
    background-position: 50%;
    background-repeat: no-repeat;
    display: inline-block;
}

// Title
.Page-title {
    @include fluid-type(font-size, $viewport--xs, $viewport--lg, $heading-size-giga / 2, $heading-size-giga);
    color: $white;
    text-align: center;
    margin-bottom: 0;
}

// Services
.ServiceColumn-object {
    background-color: $cc-cyan;
    border-radius: 100%;
    color: $white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: u($spacing-unit--sm);
    margin-bottom: u(20px);
    height: u(77px);
    width: u(77px);

    .Icon {
        height: u(36px);
    }

    svg {
        @include dimensions(100%);
        display: block;
    }
}

// Content
.Content-body {
    padding: u($spacing-unit);

    @include mq($from: $viewport--lg) {
        padding: u($spacing-unit--lg);
    }

    .Content-title {
        max-width: u(280px);
    }
}

// Authors
.Authors {
    display: flex;
    justify-content: center;
    margin-left: u($spacing-unit--lg * -1);
    margin-bottom: u($spacing-unit--lg);

    .Author {
        margin-left: u($spacing-unit--lg);
    }

    .Author-object {
        display: block;
        margin-bottom: u(20px);
    }

    .Author-image {
        border-radius: 100%;
    }
}

// Object-side
.Object-side {

    picture,
    img {
        @include dimensions(100%);
    }

    img {
        max-width: none;
        object-fit: cover;

        @include mq($from: $viewport--lg) {
            @include position(absolute, 0, 0, 0, 0);
        }
    }

    @include mq($from: $viewport--lg, $until: $structure-responsive-breakpoint - 1) {
        margin-left: u($structure-gutter--l * -1);
        width: calc(100% + #{u($structure-gutter--l)});
    }

    @include mq($from: $structure-responsive-breakpoint, $until: $structure-width--xl + 199) {
        margin-left: calc((100vw - 1230px) / -2);
        width: calc(100% + (100vw - 1230px) / 2);
    }

    @include mq($from: $structure-width--xl + 200) {
        margin-left: -5vw;
    }
}

// Form
.Form {
    max-width: u(745px);
    margin-right: auto;
    margin-left: auto;
}

// Quote
.Quote {
    @include font-size(25, false);
    font-family: $font-poppins;
    font-weight: 600;
    line-height: 1.4;
    max-width: 85%;
    margin-right: auto;
    margin-left: auto;
    .Quote-author {
        margin-top: 15px;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 300;
        &::before {
            content: '';
            width: 15px;
            height: 1px;
            background-color: $cc-cyan;
            display: inline-block;
            transform: translateY(-5px);
            margin-right: 5px;
        }
    }
    &.Align--center .Quote-author::after {
        content: '';
        width: 15px;
        height: 1px;
        background-color: $cc-cyan;
        display: inline-block;
        transform: translateY(-5px);
        margin-left: 5px;
    }
}

// Featured
.Featured-title {
    @include font-size(36, false);
    font-weight: 300;
    display: block;
    margin-bottom: u(45px);
}

// ReadingTime
.ReadingTime {
    border-left: px(1) solid $cc-grey--dark;
    padding-left: u(7.5);
}

// Extra
.Extra {
    margin-bottom: u(45);
}

// Slider
.Slider {

    &-header {
        @include grid(1fr auto, 1fr);
        margin-bottom: u(30);

        @include mq($from: $viewport--md) {
            margin-bottom: u(70);
        }

        .Projects-title {
            margin-bottom: 0;
        }

        .Slider-buttons {
            @include grid-area(2, 3, 1, 2);
            align-self: end;
            margin-left: u($spacing-unit);
            display: flex;
        }

        .Slider-button {
            position: static;
            margin-top: 0;

             + .Slider-button {
                 margin-left: u(5);

                 @include mq($from: $viewport--md) {
                     margin-left: u(10);
                 }
             }
        }
    }

    &-headerBody {
        @include grid-area(1, 2, 1, 2);
    }
}

// reCAPTCHA
.grecaptcha-badge {
    visibility: hidden;
}
