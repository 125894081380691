// Customers
.Block--cooperations {
    @include fluid-type(padding-top, $viewport--sm, $viewport--lg, 60, 120);
    @include fluid-type(padding-bottom, $viewport--sm, $viewport--lg, 60, 120);
    background-color: $cc-grey--custom;

    .Block--services + & {
        margin-top: 0;
    }

    @include mq($from: $viewport--lg) {
        padding-top: u($spacing-unit--xl);
        padding-bottom: u($spacing-unit * 3.5);
    }

    .Cooperations-logo {
        @include transition(filter, 200ms);
        filter: grayscale(100%);
        filter: brightness(0%);

        &:hover {
            //filter: grayscale(0%);
            filter: brightness(100%);
        }
    }

    .Cooperations-logo,
    .Cooperations-object {
        display: block;
        text-align: center;
        img {
            max-height: 5rem;
            width: 100%;
            object-fit: contain;
            max-width: 10rem;
        }
    }
}
