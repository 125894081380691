// Intro
.Block--intro {
    @include position(relative, $z: 3);

    + .Block--team,
    + .Block--featured {
        margin-top: u($spacing-unit--xl);
    }

    .Intro {
        padding: u($spacing-unit--lg, $spacing-unit, 0);
        text-align: center;

        @include mq($from: $viewport--md) {
            padding: u($spacing-unit--lg, $spacing-unit--lg, 0);
        }

        + .Projects {
            margin-top: u($spacing-unit--xl);
        }
    }

    .Intro-body {
        max-width: u(740px);
        margin-right: auto;
        margin-left: auto;

        :last-child {
            margin-bottom: 0;
        }

        &:not(:last-child) {
            margin-bottom: u($spacing-unit--lg);
        }

        .TeamPage & {
            color: $cc-grey--dark;
            letter-spacing: 1px;
        }

        .BlogDetailPage & {
            max-width: u(650px);
        }
    }

    .Intro-subtitle {
        @include font-size(36, false);
        font-weight: 300;
        max-width: u(500px);
        margin-right: auto;
        margin-left: auto;
    }

    .Intro-list {
        display: flex;
        margin-top: u($spacing-unit * -1);
        margin-left: u($spacing-unit * -1);
        margin-bottom: u($spacing-unit--lg);

        @include mq($from: $viewport--md) {
            justify-content: center;
            margin-left: u($spacing-unit * -2.8);
        }

        @include mq($until: $viewport--sm) {
            flex-wrap: wrap;
        }

        > .Intro-listItem {
            margin-top: u($spacing-unit);
            margin-left: u($spacing-unit);

            @include mq($from: $viewport--sm) {
                margin-left: u($spacing-unit * 2.8);

                &:nth-child(1) {
                    text-align: right;
                }

                &:not(:nth-child(2)) {
                    flex-grow: 1;
                    flex-basis: 0;
                }

                &:nth-child(3) {
                    text-align: left;
                }
            }

            @include mq($until: $viewport--sm - 1) {
                flex-grow: 1;
            }
        }
    }

    .Intro-bodyObject {
        margin-top: u($spacing-unit * 4.6)
    }

    .Tags-title {

        > a {
            color: currentColor;
            text-decoration: none;

            &:hover {
                color: $cc-cyan;
            }
        }
    }
}
