.Block--logo-slider {
    overflow: hidden;
}

.Block--logo-slider {
    overflow-x: hidden;
    width: 100%;
    @include position(relative, $z: 0);

    .Sliders {
        position: relative;
        margin-left: auto;
        width: calc(((100vw - #{$structure-width}px) / 2) + (#{$structure-width}px - 320px));
        height: 100%;

        @include mq($until: $viewport--xl) {
            width: 100%;
            padding-top: u(40);
            position: static;
        }

        @include mq($until: $viewport--lg) {
            padding-top: u(40);
        }

        .Slider {
            padding-left: u(30);

            &:first-of-type {
                left: u(30);
                margin-bottom: u(30);
            }

            &:last-of-type {
                position: relative;
                background-color: $cc-grey--light;

                @include mq($until: $viewport--lg) {
                    padding-bottom: u(40);
                }

                &::after {
                    content: "";
                    width: 100%;
                    height: 100%;
                    background-color: $white;
                    position: absolute;
                    left: 0;
                    top: 50%;

                    @include mq($until: $viewport--xl) {
                        display: none;
                    }
                }
            }

            &-item {
                width: u(274);
            }
        }
    }

    .CallToAction {
        @include position(absolute, $z: 2, $top: 0, $left: calc(((100vw - #{$structure-width}px) / 2)));
        background-color: $cc-black;
        color: $white;
        height: 100%;
        margin-left: auto;
        padding: u(70, 70, 100, 0);
        width: u(320);
        grid-column: 1/2;

        @include mq($until: $viewport--xl) {
            width: 100%;
            padding-top: u(40);
            padding-bottom: u(40);
            @include position(static);
            margin-top: u(40);
            padding: u(40, 40, 40, 40);
        }

        @include mq($until: $viewport--md) {
            margin-top: 0;
            padding: u(40, 20, 40, 20);
        }

        &::before {
            content: "";
            @include position(absolute, $top: 0, $left: -100vw);
            width: 100vw;
            height: 100%;
            background-color: $cc-black;
        }
    }

    .LogoSlider {

        &-title {
            max-width: u(620);
        }

        &-intro {
            max-width: u(690);
        }
        
        &-header {

            .Container {
                @include position(relative, $z: 0);

                @include mq($until: $viewport--xl) {
                    background-color: $cc-grey--light;
                }

                .Grid {
                    background-color: $cc-grey--light;
                    padding-top: u(72);
                    padding-bottom: u(72);
                    
                    @include mq($until: $viewport--xl) {
                        padding-bottom: u(0);
                    }
                }

                &::after {
                    content: "";
                    @include position(absolute, $z: 0, $right: calc(-100vw + 40px), $top: 0);
                    height: 100%;
                    width: 100vw;
                    background-color: $cc-grey--light;

                    @include mq($until: $viewport--md) {
                        @include position(absolute, $z: 0, $right: calc(-100vw + 20px), $top: 0);
                    }
                }
            }
        }
        
        &-body {
            background-color: $cc-grey--light;
            position: relative;
            display: block;

            &::after {
                content: "";
                width: 100%;
                height: 100%;
                max-height: 150px;
                background-color: $white;
                position: absolute;
                left: 0;
                bottom: 0;

                @include mq($until: $viewport--xl) {
                    display: none;
                }
            }
        }

        &-logo {
            background-color: $white;
            width: 100%;
            height: u(164);
            display: block;
            padding: u(40);
            box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.02);
            border: 1px solid $cc-grey--light;

            * {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}
