/* Colors */
.Nav--colors {
    @include font-size(14, false);

    .Nav-list {
        margin-top: u($spacing-unit--sm * -1);
        margin-left: u($spacing-unit--sm * -1);
    }

    .Nav-item {
        border-radius: u(20px);
        color: $white;
        font-weight: 600;
        letter-spacing: 1px;
        margin-top: u($spacing-unit--sm);
        margin-left: u($spacing-unit--sm);
        padding: u(8px, 17px);
        text-transform: uppercase;
    }
}
