.Block {
    display: flex;
    flex-direction: column;
    align-items: center;

    & + & {
        @include fluid-type(margin-top, $viewport--sm, $viewport--lg, 60, 120);
    }

    &-anchor {
        @include position(relative);
        @include fluid-type(top, $viewport--sm, $viewport--lg, -60, -120);
    }

    &.has-grid {

        > .Container {

            @include mq($from: $viewport--lg) {
                @include grid(35% u(90) 1fr, auto);

                .Media-object,
                .Grid-body {
                    @include position(relative, $z: 1);
                }
            }
        }

        .Media-object {

            .MediaItem {

                &-object,
                &-image {
                    @include dimensions(100%);
                }

                &-image {
                    object-fit: cover;
                }
            }
        }
    }

    &:last-child {

        .Main & {
            margin-bottom: u($spacing-unit--lg);

            @include mq($from: $viewport--md, $until: $viewport--lg - 1) {
                margin-bottom: u($spacing-unit--xl);
            }

            @include mq($from: $viewport--lg) {
                margin-bottom: u($spacing-unit * 5.8);
            }
        }
    }
}
