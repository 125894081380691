/* Tags */
.Nav--tags {

    .Nav-link {
        text-decoration: none;

        &,
        &:hover {
            color: currentColor;
        }

        &:hover {
            text-decoration: underline;
        }

        .HomePage & {
            @include position(relative, $z: 0);
            display: inline-block;
            padding: u(0, $spacing-unit--xs);

            &::before {
                @include position(absolute, $top: 50%, $right: 50%, $left: 50%, $z: -1);
                @include transition(#{right, left}, 200ms);
                background-color: rgba($white, .3);
                content: '';
                height: 25%;
                transform: translateY(-50%);
            }

            &:hover {
                text-decoration: none;

                &::before {
                    right: 0;
                    left: 0;
                }
            }
        }

        .Intro & {
            @include position(relative, $z: 0);
            display: inline;
            padding: u(0, $spacing-unit--xs);

            &::before {
                @include position(absolute, $top: 50%, $right: 50%, $left: 50%, $z: -1);
                @include transition(#{right, left}, 200ms);
                background-color: rgba($cc-cyan, .5);
                content: '';
                height: 25%;
                transform: translateY(-50%);
            }

            &:hover {
                text-decoration: none;

                &::before {
                    right: 0;
                    left: 0;
                }
            }
        }
    }
}

.Nav--tags {

    .Nav-list {
        margin-left: u($spacing-unit--xs * -1);
    }

    .Nav-item {
        margin-left: u($spacing-unit--xs);
    }
}
