/**
 * Colors: Custom
 */

// Grey
$cc-grey:                           #e6e1de !default;
$cc-grey--alt:                      #acacac !default;
$cc-grey--dark:                     #898989 !default;
$cc-grey--darker:                   #5a5a5a !default;
$cc-grey--light:                    #f7f7f7 !default;
$cc-grey--hover:                    scale-color($cc-grey, $lightness: -30%);
$cc-grey--border:                   #e7e7e7 !default;
$cc-grey--custom:                   #f2f2f2 !default;

// Black
$cc-black:                          #0a0a0a !default;

// Cyan
$cc-cyan:                           #3650DC !default;
$cc-dark-blue:                      #0E228C !default;

// States
$success-text-color:                scale-color($green, $saturation: -50%) !default;
$success-bg-color:                  scale-color($green, $lightness: 90%, $saturation: -25%) !default;
$success-border-color:              scale-color($success-bg-color, $lightness: -5%) !default;

$info-text-color:                   scale-color($blue, $saturation: -50%) !default;
$info-bg-color:                     scale-color($blue, $lightness: 90%, $saturation: -25%) !default;
$info-border-color:                 scale-color($info-bg-color, $lightness: -5%) !default;

$warning-text-color:                scale-color($orange, $saturation: -50%) !default;
$warning-bg-color:                  scale-color($orange, $lightness: 90%, $saturation: -25%) !default;
$warning-border-color:              scale-color($warning-bg-color, $lightness: -5%) !default;

$danger-text-color:                 scale-color($red, $saturation: -50%) !default;
$danger-bg-color:                   scale-color($red, $lightness: 90%, $saturation: -25%) !default;
$danger-border-color:               scale-color($danger-bg-color, $lightness: -5%) !default;

// Lay-out
$text-color:                        $cc-black !default;
$background-color:                  $white !default;
$border-color:                      $cc-grey !default;
$image-color:                       $cc-grey !default;
