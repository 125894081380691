/* Footer */
.Nav--footer {

    .Nav-list {
        margin-left: u($spacing-unit * -1);
    }

    .Nav-item {
        margin-left: u($spacing-unit);
    }

    .Nav-link {
        text-decoration: underline;

        &,
        &:hover {
            color: currentColor;
        }

        &:hover {
            text-decoration: none;
        }
    }
}
