.Card {
    display: flex;
    flex-direction: column;
    padding: u(40);
    min-height: 100%;

    @include mq($from: $viewport--lg) {
        padding: u(50);
    }
}

.Card-object {
    @include dimensions(40.38, 50);
    fill: currentColor;
    margin-bottom: auto;
}

.Card-body {
    margin-top: u(40);

    @include mq($from: $viewport--lg) {
        margin-top: u(50);
    }
}

.Card-title {
    @include fluid-type(font-size, $viewport--sm, $viewport--md, $heading-size-3 * .75, $heading-size-3);
    margin-bottom: u(30);
}

// Theme
.Card--black {
    background-color: $cc-black;
    color: $white;
}

.Card--blue {
    background-color: $cc-cyan;
    color: $white;

    .Button--primary {
        color: $cc-cyan;

        .Icon-item--line {
            color: currentColor;
        }

        &::before {
            background-color: $white;
        }
    }
}

.Card--grey {
    background-color: $cc-grey--light;
    color: $cc-black;

    .Card-object {
        color: $cc-grey;
    }
}
