/* Main */
html {
    background-color: $background-color;
    color: $text-color;
    font-family: $font-sans;
    height: 100%;
    -webkit-tap-highlight-color: rgba($black, 0);

    &.fonts-loaded {
        font-family: $font-poppins;
        font-weight: 300;
    }

    &.hide-scroll {
        overflow: hidden;
    }
}

body {
    min-height: 100%;
}
