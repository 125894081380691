// Strategry
.Block--strategy {
    position: relative;

    @include mq($from: $structure-responsive-breakpoint, $until: $structure-width--xl + 199) {

        &::before {
            @include position(absolute, $top: 0, $bottom: 0, $left: 0);
            background-color: $cc-grey--light;
            content: '';
            width: calc((100vw - 1230px) / 2);
        }
    }

    > .Container {
        min-height: u(468px);

        @include mq($from: $viewport--lg) {
            @include grid((100% / 1230 * 325) 1fr, 100%);

            &::before {
                @include grid-area(1, 3, 1, 2);
                background-color: $cc-grey--light;
                content: '';
            }
        }
    }

    .Service-title {
        margin-bottom: u($spacing-unit * 1.4);
        text-align: left;
    }

    .Service-object {
        @include grid-area(1, 2, 1, 2);
        background-color: $cc-grey--light;
        position: relative;

        @include mq($from: $viewport--md, $until: $viewport--lg - 1) {
            padding-bottom: calc(100% / 3 * 1);
            height: 0;
            position: relative;

            img {
                @include position(absolute, 0, 0, 0, 0);
            }
        }
    }

    .Service-body {
        background-color: $cc-grey--light;
        @include grid-area(2, 3, 1, 2);
        align-self: center;
        padding: u($spacing-unit--lg, $spacing-unit);

        @include mq($until: $viewport--md - 1) {
            text-align: left;
        }

        @include mq($from: $viewport--md) {
            padding: u(85px, 65px, 65px);
        }
    }
}
