.Block--hero {
    margin-top: u($structure-header-height * -1);
    padding-top: u($structure-header-height);
    padding-bottom: u(50);

    @include mq($from: $viewport--md) {
        padding-top: u($structure-header-height + 70);
        padding-bottom: u(140);
    }

    &.Block--black {
        background-color: $cc-black;
        color: $white;
    }
}

.Hero-header {
    max-width: u(840);

    &:not(:last-child) {
        margin-bottom: u(40);

        @include mq($from: $viewport--md) {
            margin-bottom: u(80);
        }
    }
}

.Hero-title {
    line-height: (52 / 45);

    @include mq($until: $viewport--md - 1) {

        br {
            display: none;
        }
    }

    @include mq($from: $viewport--md) {
        font-size: u(45);
    }
}

.Hero-text {
    max-width: u(740);
    letter-spacing: px(1);
}

.Hero-title,
.Hero-subtitle {
    margin-bottom: u(20);

    @include mq($from: $viewport--md) {
        margin-bottom: u(30);
    }
}
