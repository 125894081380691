/**
 * Form
 */

// Item
.#{$form-namespace}Form-item {
    position: relative;

    & + & {
        margin-top: u($form-item-spacing);
    }

    &--action {

        .Form-item + &,
        .Grid + & {
            margin-top: u($spacing-unit--lg);
        }
    }

    &.Form-item--list {
        max-width: u(600px);
        margin-top: u($spacing-unit--lg);
        margin-right: auto;
        margin-left: auto;

        @include font-size(14, false);
        color: $cc-grey--darker;
        letter-spacing: 1px;
        line-height: 1.57;

        .Form-label {
            margin-bottom: u($spacing-unit);

            &::after {
                content: none !important;
            }
        }
    }
}

// Label
.#{$form-namespace}Form-label {
    display: inline-block;
    // margin-bottom: u($form-label-spacing--b);

    .Form-item--input & {
        position: absolute;
        transform: translateY(24px) scale(1);
        transform-origin: top left;
        transition: transform .2s;
        z-index: 2;
    }

    .Form-item--input.is-active & {
        transform: translateY(0) scale(.75);
    }

    // Style label if it's a optional item
    .#{$form-namespace}Form-item:not(.is-required) > &::after {
        content: 'Optional';
        font-size: 65%;
        font-weight: 300;
        text-transform: uppercase;
        margin-left: u($spacing-unit--xs);

        html[lang^="nl"] & {
            content: 'Optioneel';
        }

        html[lang^="fr"] & {
            content: 'Facultatif';
        }
    }

    // Error
    .#{$form-namespace}Form-item.is-error & {
        color: $form-error-color;
    }

    // Success
    .#{$form-namespace}Form-item.is-success & {
        color: $form-success-color;
    }
}

// Help & Validation
.#{$form-namespace}Form-help {
    margin-top: u($spacing-unit--xs);
    color: $form-help-color;
    font-size: u($form-help-font-size);

    &:first-child {
        margin-top: 0;
        margin-bottom: u($spacing-unit--sm);
    }

    & :last-child {
        margin-bottom: 0;
    }

    & + & {
        margin-top: u($spacing-unit--sm);
        padding-top: u($spacing-unit--sm);
        border-top: 1px solid $form-divider-color;
    }
}

.#{$form-namespace}Form-help--validation {

    // Error
    .#{$form-namespace}Form-item.is-error & {
        color: $form-error-color;
    }

    // Success
    .#{$form-namespace}Form-item.is-success & {
        color: $form-success-color;
    }
}

// Controls
.#{$form-namespace}Form-controls {
    position: relative;
}

// Horizontal
@include mq($from: $viewport--sm) {
    .#{$form-namespace}Form--horizontal {

        .#{$form-namespace}Form-item {
            display: flex;
            flex-flow: row wrap;
            align-items: flex-start;
        }

        // Label
        .#{$form-namespace}Form-label {
            margin-top: u($form-label-spacing--t);
            margin-bottom: 0;
            padding-right: u($spacing-unit);
            width: percentage($form-label-width);
        }

        // Controls
        .#{$form-namespace}Form-controls {
            width: percentage($form-controls-width);
        }

        // Actions
        .#{$form-namespace}Form-item--action {
            padding-left: percentage($form-label-width);
        }
    }
}
