.Block--bookHeader {
    color: white;
    overflow: hidden;

    @include mq($until: $viewport--md - 1) {
        background-color: $black;
    }

    &.is-fold:last-child {
        margin-bottom: 0;
    }

    > .Container {

        @include mq($from: $viewport--md) {
            @include grid(26.42276% 22% 1fr, 1fr 15vh);

            @include parent-nth-status(1, '.is-landscape', suffix) {
                grid-template-columns: 1fr 1fr 1fr;
            }

            &::before {
                @include position(relative, $right: 50%, $left: 50%, $z: -1);
                @include grid-area(1, 4, 1, 2);
                background-color: $cc-black;
                content: '';
                display: block;
                margin-left: -50vw;
                margin-right: -50vw;
                width: 100vw;
            }
        }
    }

    .Media-object {
        align-self: end;
        margin-top: u($spacing-unit--lg);
        margin-bottom: u($spacing-unit--lg);

        picture {
            background-color: transparent;
        }

        img {

            @include mq($from: $viewport--md) {
                max-height: calc(100vh - #{u($structure-header-height + $spacing-unit--lg * 2)});
                width: auto;
            }
        }

        @include mq($from: $viewport--md) {
            @include grid-area(1, 3, 1, 3);
            margin-right: u($spacing-unit--lg);
        }
    }

    .bookHeader {

        &-header {
            display: flex;
            flex-direction: column-reverse;
        }

        &-title {
            @include fluid-type(font-size, $viewport--sm, $viewport--lg, 32, 40);
            color: $white;
            font-weight: 600;
            max-width: u(500);
            line-height: 1.25;
        }

        &-subtitle {
            @include font-size(18, false);
            color: $cc-cyan;
            font-weight: 600;
            letter-spacing: normal;
            text-transform: uppercase;
        }

        &-body {
            position: relative;

            p {
                max-width: u(375);
                font-size: 22px;
                font-family: $font-poppins;
                font-weight: 600;
            }

            @include mq($from: $viewport--md) {
                @include grid-area(3, 4, 1, 2);
                max-width: 500px;
                align-self: center;
                justify-self: center;
                padding-top: u($spacing-unit--lg);
                padding-bottom: u($spacing-unit--lg);
            }
        }
    }

    .Icon--download {
        @include dimensions(15);
        align-self: center;
        padding-top: 0;
    }
}

.Block--bookContent {

    > .Container {
        // display: flex;
        // justify-content: space-between;

        &--small {
            max-width: u($structure-width--sm - 70px + $structure-responsive-gutter--r + $structure-responsive-gutter--l);

            // Responsive
            @include mq($from: $structure-width--xl + 200) {
                max-width: u($structure-width--lg - 70px + if($structure-responsive-gutter--r > 0 and $structure-gutter--r != $structure-responsive-gutter--r, $structure-gutter--r, null) + if($structure-responsive-gutter--l > 0 and $structure-gutter--l != $structure-responsive-gutter--l, $structure-gutter--l, null));
            }

            @include mq($from: $viewport--md, $until: $structure-width--xl + 200 - 1) {
                max-width: u($structure-width--sm - 70px + if($structure-responsive-gutter--r > 0 and $structure-gutter--r != $structure-responsive-gutter--r, $structure-gutter--r, null) + if($structure-responsive-gutter--l > 0 and $structure-gutter--l != $structure-responsive-gutter--l, $structure-gutter--l, null));
            }
        }
    }

    @include mq($from: $viewport--lg) {

        .Columns {
            display: grid;
        }
    }

    .bookContent {

        &-title {
            @include grid-area(1, 2, 1, 3);
            color: $cc-black;
            max-width: u(300);
            align-self: start;
        }

        &-body {
            @include grid-area(3, 4, 1, 3);

            @include mq($from: $viewport--md) {
                max-width: u(500 + $spacing-unit--lg);
                // padding-left: u($spacing-unit--lg);
            }
        }
    }
}

.Block.Block--bookContent,
.Block.Block--bookPromo {

    .Columns {
        grid-template-columns: 26.42276% 22% 1fr;
    }
}

.Block[class*=book].Block--bookPromo {
    color: $cc-cyan;

    ul:not([class]) {

        @include mq($until: $viewport--md - 1) {
            text-align: left;
        }

        li {

            &::before {
                background-image: url('/assets/img/bullet2.svg');
            }
        }
    }

    .bookPromo-title {
        color: $cc-black;
        max-width: u(500);

        @include mq($from: $viewport--sm, $until: $viewport--lg - 1) {
            margin-right: auto;
            margin-left: auto;
        }
    }
}

.Block--bookSlider {
    overflow: hidden;

    > .Container {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        &--xsmall {
            max-width: u(840 + 40px + 40px);

            // Responsive
            @include mq($from: $viewport--md) {
                max-width: u(840 + 75px + 75px);
            }
        }
    }

    .Slider {

        &-button {

            &--prev {
                transform: translateX(-50%);
            }

            &--next {
                transform: translateX(50%);
            }
        }
    }

    .bookSlider {

        &-object {
            position: relative;
            margin-bottom: u(60);

            &,
            .Slider,
            .Slider-items,
            .Slider-image {
                max-width: u(750);
                width: 100%;
            }
        }

        &-header {
            max-width: u(500);
            margin-bottom: u(30);
            text-align: center;
        }

        &-title {
            margin-bottom: u(30);
        }

        &-body {
            text-align: center;

            > p {
                font-size: 36px;
                font-weight: 300;
                max-width: u(530);
                line-height: 1.25;
                opacity: 0.92;
                margin-bottom: u(30);
            }
        }
    }
}

.Block[class*=book] {

    ul:not([class]) {
        list-style: none;
        padding-left: u(38);

        @include mq($from: $viewport--sm, $until: $viewport--lg - 1) {
            margin-right: auto;
            margin-left: auto;
        }

        li {
            margin-bottom: u(12);

            &:before {
                background-image: url('#{$path-img}bullet.svg');
                background-repeat: no-repeat;
                background-size: 17px 17px;
                display: inline-block;
                content: '';
                height: u(16);
                margin-top: u(-4.5);
                margin-left: u(-38);
                margin-right: u(21);
                vertical-align: middle;
                width: u(17);
            }
        }
    }

    // @include mq($from: $viewport--md) {

    //     &:last-child:not(.is-fold) {
    //         margin-bottom: u(75);
    //     }
    // }
}

@include mq($until: $viewport--md - 1) {

    .Block--bookHeader {
        padding-bottom: u(45);
        height: auto;
        padding-bottom: u($spacing-unit--lg);

        .Media-object {
            margin-top: 0;
            margin-bottom: u($spacing-unit);

            img {
                max-width: 50%;
            }
        }
    }

    .Block--bookContent {

        > .Container {
            flex-direction: column;
        }
    }
}

.Block--Book {

    .Heading {

        &--Fold {
            background-color: $black;
            min-height: calc(100vh - #{u($structure-header-height)});

            .Overzicht {
                color: white;

                > .Container {
                    display: flex;
                }
            }

            .Media-object {
                margin-right: u($spacing-unit--lg);
                width: 50%;

                @include mq($until: $viewport--md - 1) {
                    display: none;
                }
            }

            .MediaItem-object {

                @include mq($from: $viewport--md, $until: $structure-responsive-breakpoint - 1) {
                    margin-left: u($structure-gutter--l * -1);
                }

                @include mq($from: $structure-responsive-breakpoint) {
                    margin-left: calc((100vw - #{u($structure-width)}) / -2);
                }
            }

            .MediaItem-image {
                max-width: none;
                width: 100%;
                height: calc(100vh - #{u($structure-header-height)});
                object-fit: cover;
            }

            .Heading--Text {
                margin-left: auto;
                margin-right: auto;
                align-self: center;
            }
        }
    }
}
