.SideBox {
    background-color: $cc-grey--light;
    padding: u(30, 40);

    @include mq($from: $viewport--md) {
        max-width: u(265);
        margin-left: auto;
    }

    & + & {
        margin-top: u(30);
    }

    &-title {
        line-height: 1.36;
        letter-spacing: 0;
        margin-bottom: u(15);
    }
}
