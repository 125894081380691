/**
 * Header
 */

body {
    padding-top: u(105px);
}

.Header {
    @include transition(#{background-color, transform}, 200ms);
    @include position(fixed, $top: 0, $right: 0, $left: 0, $z: 20);
    background-color: $white;
    color: $cc-black;
    padding-top: u($spacing-unit);
    padding-bottom: u($spacing-unit);

    &.is-unpinned {
        transform: translateY(-100%);
    }

    .Logo {
        order: -1;
    }

    > .Container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    @include mq($until: $viewport--md - 1) {

        .show-nav & {
            background-color: $black;
            color: $white;
        }
    }

    &--black {
        background-color: $cc-black;
        color: $white;
    }
}
