.Block--score {
    overflow: hidden;

    >.Container {

        @include mq($from: $viewport--lg) {

            @include parent-nth-status(1, '.has-grid', suffix) {
                @include grid(80% 1fr, 86% 1fr);
            }
        }
    }

    .Label--bottom {
        left: 0;
        transform: rotate(-90deg) translateY(-30px) translateY(100%);
    }

    .Button {
        margin-top: 0;
        color: $white;
    }

    .Media-object {
        @include grid-area(2, 4, 1, 4);
        min-height: u(525);

        @include mq($from: $viewport--lg, $until: 1299) {
            margin-right: u($structure-gutter--r * -1);
            width: calc(100% + #{u($structure-gutter--r)});
        }

        @include mq($from: 1300, $until: 1799) {
            margin-right: calc((100vw - #{u(1230)}) / -2);
            width: calc(100% + (100vw - #{u(1230)}) / 2);
        }

        @include mq($from: 1800) {
            margin-right: calc((100vw - #{u(1600)}) / -2);
            width: calc(100% + (100vw - #{u(1600)}) / 2);
        }

        .MediaItem-object {
            display: block;
        }

        .MediaItem-image {
            position: absolute;
            object-fit: cover;
        }
    }

    .Icons {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        &-item {
            display: flex;
            padding-right: u(15);
            padding-left: u(15);

            @include mq($until: $viewport--md - 1) {

                + .Icons-item {
                    margin-top: u(45);
                }
            }

            @include mq($from: $viewport--md) {
                padding-top: u(22.5);
                padding-bottom: u(22.5);
                width: 50%;
            }

            :last-child {
                margin-bottom: 0;
            }
        }

        &-body {
            width: 96%;
        }

        &-object {
            flex-shrink: 0;
            margin-right: u(20);

            circle,
            ellipse,
            line,
            path,
            polygon,
            polyline,
            rect {

                &[fill]:not([fill="none"]) {
                    fill: currentColor !important;
                }

                &[stroke]:not([stroke="none"]) {
                    stroke: currentColor !important;
                }
            }
        }
    }

    @include mq($until: $viewport--lg - 1) {

        > .Container {
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
        }

        .Media-object {
            position: relative;
            width: 100%;
        }
    }

    @include mq($until: $viewport--md - 1) {

        .Icons {
            width: 100%;
        }
    }

    @include mq($until: 425) {

        .Icons-item {
            display: flex;
            flex-direction: column;
        }

        .Icons-object {
            margin-bottom: u(15);
        }
    }
}

.Score {
    @include grid-area(1, 2, 1, 3);
    background-color: $cc-black;
    color: $white;
    display: flex;
    flex-direction: column;
    padding: u(60, $structure-responsive-gutter--r);

    @include mq($from: $viewport--md) {
        padding: u(30, 60);
    }

    .Icons {
        margin-top: auto;
        margin-bottom: auto;
    }

    &-link {
        text-align: center;
        margin-top: u(40);

        @include mq($from: $viewport--md) {
            margin-top: u(20);
        }
    }
}
