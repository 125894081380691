.Banner--sticky {
    @include font-size(18);
    @include position(absolute, $top: 0, $right: 0, $left: 0, $z: 15);
    background-color: $cc-cyan;
    color: $white;
    text-align: center;
    padding: u($spacing-unit--sm, 0);

    .Button--primary {
        font-weight: normal;

        @include mq($until: $viewport--md - 1) {
            @include font-size(18);

            &::before {
                // width: 100%;
                height: 100%;
            }
        }

        .Icon {
            padding-top: 9px;
        }
    }
}
