/* SocialMedia */
.Nav--socialMedia {

    .Block--media & {
        @include position(absolute, $top: 0, $bottom: 0, $left: 100%);
    }

    .Nav-list {

        @include mq($until: $viewport--md - 1) {

            .show-nav & {
                @include position(fixed, $right: 0, $bottom: 0, $left: 0, $z: 20);
                padding: u($spacing-unit--lg);
                justify-content: center;
            }
        }
    }

    &:not(.Nav--stacked) {
        height: u(24);

        .Nav-item {

            + .Nav-item {
                margin-left: u($spacing-unit--sm);
            }
        }

        a.Nav-link {
            color: $white;

            &:hover {
                color: $cc-cyan;
            }
        }

        .Nav-icon {
            display: block;
            fill: currentColor;
            height: 24px;
            width: 24px;
        }
    }

    &.Nav--stacked {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include mq($until: 1330) {
            display: none;
        }

        @include mq($from: 1430) {
            margin-left: u(20);
            padding-left: u($spacing-unit--lg);
        }

        a.Nav-link {
            color: rgba($cc-grey, 0.4);
            padding: u(7.5);

            &:hover {
                color: $white;
            }
        }

        .Nav-icon {
            @include dimensions(18);
            display: block;
            fill: currentColor;
        }
    }


    a.Nav-link {
        @include transition(color, 200ms);
    }
}
