.Block--projects {

    .Slider:not(.Slider--initialized) {

        > .Slider-items {
            margin-left: u(-118);

            > .Slider-item {
                padding-left: u(118);
                width: 50%;
            }
        }
    }
}

.Label--projects {
    text-transform: uppercase;
}

.Projects-title {
    @include font-size(36, false);
    @include fluid-type(font-size, $viewport--sm, $viewport--lg, 28, 36);
    font-weight: 300;
    max-width: u(500px);
}
.Projects-intro {
    max-width: u(740);
    letter-spacing: px(1);
}

.Projects {

    &:not(.Projects--alt) {

        @include mq($from: $viewport--sm) {
            margin-top: u($spacing-unit--xl * -1);
        }
    }

    > .Project {
        margin-top: u($spacing-unit--lg);

        @include mq($from: $viewport--sm) {
            margin-top: u($spacing-unit--xl);
        }

        @include mq($from: $viewport--sm) {
            float: left;
        }

        @include mq($from: $viewport--sm, $until: $viewport--sm - 1) {
            max-width: u(492px);
            margin-right: auto;
            margin-left: auto;
        }

        @include mq($until: $viewport--sm - 1) {

            &--info {
                margin-top: 0;
            }
        }

        @include mq($from: $viewport--sm) {
            width: (100% / 1110 * 492);
        }
    }
}

.Projects--alt {

     > .Project:first-of-type {
        margin-top: 0;

        @include mq($from: $viewport--sm) {
            margin-top: 0;
        }
    }
}

.Project-gutter {
    width: (100% - ((100% / 1110 * 492) * 2));
}

.Project {
    @include position(relative, $z: 1);
    width: 100%;

    .Button {
        flex-shrink: 0;
    }

    &:not(.Project--cta) {

        .Project-title {
            margin-bottom: u($spacing-unit--xs / 2);
        }
    }

    &--cta {
        background-color: $cc-grey--light;
        display: flex;
        flex-direction: row;

        .Project-inner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            min-height: 245px;
            padding: u($spacing-unit--lg);

            @include mq($from: $viewport--sm, $until: $viewport--lg - 1) {
                padding: u($spacing-unit * 1.25);
            }

            @include mq($from: $viewport--lg) {
                padding: u($spacing-unit * 1.25, $spacing-unit--lg);
            }
        }
    }
}

.Project-body {

    @include mq($from: $viewport--lg) {
        display: flex;
        align-items: flex-end;
    }
}

.Project-object {
    position: relative;
    padding-bottom: (100% / 16 * 15);

    .Project-image {
        @include position(absolute, 0, 0, 0, 0, 1);
    }

    @include hover() {
        background-color: $cc-black;
        overflow: hidden;

        &.has-placeholder {
            background-image: none;
        }

        &:hover {

            .Project-image {
                opacity: 0.8;
                transform: scale(1.1);
            }
        }
    }


    &, img {
        display: block;
        width: 100%;
    }
}

.Project-image {

    @include hover() {
        @include transition(#{opacity, transform}, 200ms);
    }
}

.Project-bodyItem {
    margin-bottom: u($spacing-unit--sm);

    @include mq($from: $viewport--lg) {
        margin-right: u($spacing-unit);
        margin-bottom: 0;
    }

    @include mq($from: $viewport--sm) {
        flex-grow: 1;

        :last-child {
            margin-bottom: 0;
        }
    }
}

.Project-title {
    @include font-size(17, false);
    line-height: 1.53;
}

.Project-link {
    display: block;
    margin-bottom: u($spacing-unit);
}
