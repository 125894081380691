// Text
.Block--text:not(.Block--case) {
    background-color: $cc-grey--light;

    > .Container {
        padding: u(30);
        max-width: u(1620);

        div {
            width: 50%;
            margin: 0 auto;
            grid-column: 1/4;
        }

        .hidden {
            grid-column: 1/4;
            display: flex;
            justify-content: center;
        }

        @include mq($until: $viewport--md - 1) {

            div {
                width: 80vw;
            }
        }
    }
}
