// Call to Action
.Block--cta {

    .Label--bottom {
        right: auto;
        left: 0;
        flex-direction: row;

        @include mq($from: $structure-responsive-breakpoint + 60) {
            transform: rotate(-90deg);
        }

        @include mq($from: $viewport--md, $until: $structure-responsive-breakpoint + 60 - 1) {
            transform: rotate(-90deg) translateY(140%);
        }


        &::after {
            margin-left: u($spacing-unit--sm);
            margin-right: 0;
        }
    }

    .Cta {
        background-color: $cc-grey--light;
        overflow: hidden;

        @include mq($from: $viewport--lg) {

            &.has-noImg {
                max-width: u(1070);
                margin-right: auto;
                margin-left: auto;
            }

            &:not(.has-noImg) {
                @include grid((100% / 3) 1fr, none);
            }
        }
    }

    .Cta-object {

        &, img {
            width: 100%;
        }

        @include mq($from: $viewport--lg) {
            @include grid-area(1, 2, 1, 2);
        }
    }

    .Cta-image {
        height: 100%;
        object-fit: cover;
    }

    .Cta-body {
        @include font-size(20, false);
        padding: u($spacing-unit * 1.5);

        @include mq($from: $viewport--lg) {
            @include grid-area(2, 3, 1, 2);
            padding: u(90px, $spacing-unit--xl);
        }

        a:not(.Button) {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }

            &, &:hover {
                color: currentColor;
            }
        }
    }

    .Cta-title {
        @include font-size(25, false);
    }

    .Cta-subtitle {
        @include font-size(22, false);
    }

    .Button {
        margin-top: u($spacing-unit * 1.5);
    }
}

.Block--ctaForm {

    a[name] {
        @include position(relative, $top: u($spacing-unit * -1.5));
        display: block;
    }

    .Cta-body {
        font-size: inherit;
        padding: u($spacing-unit * 1.5);

        @include mq($from: $viewport--lg) {
            padding: u(60px, $spacing-unit--xl);
        }
    }

    .Button {
        margin-top: 0;
    }
}
