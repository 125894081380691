.Nav--pagination {

    .Block--more & {
        @include font-size(25, false);
        font-weight: 500;
        line-height: 1.04;

        .Label {
            display: block;
            margin-bottom: u($spacing-unit);
        }

        @include mq($until: $viewport--md - 1) {

            .Nav-list {
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .Nav-item:not(:first-child) {
                    margin-top: u($spacing-unit--sm);
                }
            }

            .Nav-item--separator {
                display: none;
            }
        }

        @include mq($from: $viewport--md) {

            .Nav-item:not(.Nav-item--separator) {
                flex-grow: 1;
                flex-basis: 0;

                &:first-child {
                    text-align: right;
                }
            }
        }

        .Nav-item {

            &:only-child {
                text-align: center !important;
            }

            + .Nav-item:not(.Nav-item--separator) {
                display: flex;
                align-items: center;
            }

            &--separator {
                margin-right: u($spacing-unit);
                margin-left: u($spacing-unit);
            }
        }

        .Nav-link {
            @include transition(color, 200ms);

            &, &:hover {
                color: currentColor;
            }

            &:hover {
                color: $cc-cyan;
            }
        }
    }

    .Block--cases & {

        .Nav-list {
            justify-content: center;
        }

        .Nav-link {
            @include dimensions(30, 26);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &,
            &:active,
            &:focus,
            &:hover {
                color: $cc-black;
            }

            &.is-active {
                background-color: $cc-black;
                color: $white;
            }
        }
    }
}
