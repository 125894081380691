/**
 * Elements: Link
 */

// Text decoration
$link-text-decoration:                    underline !default;
$link-text-decoration--hover:             none !default;

// Colors
$link-color:                              $cc-cyan !default;
$link-color--hover:                       $cc-cyan !default;
$link-fontWeight:                          400;
