/* Button */

.Button {
    @include transition(color, 300ms);
    @include position(relative, $z: 1);
    color: $cc-black;
    display: inline-flex;
    flex-direction: row-reverse;
    font-weight: 500;
    letter-spacing: px(1);

    .Icon {
        @include transition(#{color, margin}, 300ms);
        align-self: flex-start;
        color: currentColor;
        margin-right: u(31px);
        padding-top: u(5.5px);
        transition-timing-function: cubic-bezier(0.65, 0, .076, 1);

        &-object {
            @include dimensions(24, 14);
            @include transition(margin, 300ms);
            margin-left: u(-16px);
            transition-timing-function: cubic-bezier(0.65, 0, .076, 1);

            .Icon-item--line {
                @include transition(opacity, 300ms);
                opacity: 0;
                transition-timing-function: cubic-bezier(0.65, 0, .076, 1);
            }
        }

        @include hover(none) {
            margin-right: u(15);

            &-object {
                margin-left: 0;

                .Icon-item--line {
                    color: currentColor;
                    opacity: 1;
                }
            }
        }
    }

    @include hover() {

        &::before {
            @include position(absolute, $top: 0, $left: 0, $z: -1);
            @include transition(#{background-color, width}, 300ms);
            background-color: $cc-grey--light;
            border-radius: u(40);
            content: '';
            width: u(40);
        }

        &:not(.Button--secondary):not(.Button--primary):not(.Button--download) {

            .Icon {
                color: $cc-cyan;
            }

            &::before {
                height: 100%;
                min-height: u(40);
            }
        }

        &:hover,
        &.is-hovered,
        &:focus,
        &.is-focused,
        &:active,
        &.is-active {
            color: $white;

            &:not(.Button--secondary):not(.Button--primary):not(.Button--download) {

                &::before {
                    background-color: $cc-cyan;
                }

                .Icon {
                    color: $white;
                }
            }

            &::before {
                width: 100%;
            }

            .Icon {
                margin-right: u(15);

                &-object {
                    margin-left: 0;

                    .Icon-item--line {
                        opacity: 1;
                    }
                }
            }
        }
    }

    @include hover(none) {
        background-color: $cc-cyan;
        border-radius: u(40);
        position: relative;
        overflow: hidden;

        &:after {
            @include dimensions(calc(var(--d) * 1px), calc(var(--d) * 1px));
            @include position(absolute, $top: calc(var(--y) * 1px), $left: calc(var(--x) * 1px));
            content: '';
            background-color: var(--ripple-background, $white);
            border-radius: 50%;
            pointer-events: none;

            //  animated properties
            opacity: calc(var(--o, 1) * var(--ripple-opacity, 0.3));
            transition: calc(var(--t, 0) * var(--ripple-duration, 600ms)) var(--ripple-easing,linear);
            transform: translate(-50%, -50%) scale(var(--s, 1));
            transform-origin: center;
        }

        &,
        &:active,
        &:focus,
        &:hover {
            color: $white;
        }
    }

    // Large
    &--large {
        @include font-size(17, false);
        padding-top: u(10.5px);
        padding-bottom: u(10.5px);

        @include hover() {

            &::before {
                border-radius: u(50px);
                bottom: 0;
                width: u(50px);
            }

            .Icon {
                margin-right: u(29px);

                @include mq($from: $viewport--md) {
                    padding-top: u(7.5px);
                }

                &-object {
                    margin-left: u(-14px);

                    .Icon-item--line {
                        @include transition(#{opacity, color}, 300ms);
                        opacity: 0;
                        transition-timing-function: cubic-bezier(0.65, 0, .076, 1);
                    }
                }
            }
        }
    }

    // Alt
    &--alt {

        @include hover() {
            color: $cc-grey--alt;

            &::before {
                background-color: $white;
            }

            &:not(.Button--secondary):not(.Button--primary):not(.Button--download) {

                .Icon {
                    color: $cc-cyan;
                }

                &::before {
                    height: 100%;
                    min-height: u(40);
                }
            }

            &:hover,
            &.is-hovered,
            &:focus,
            &.is-focused,
            &:active,
            &.is-active {
                color: $white;

                &:not(.Button--secondary):not(.Button--primary):not(.Button--download) {

                    &::before {
                        background-color: $cc-cyan;
                    }

                    .Icon {
                        color: $white;
                    }
                }
            }
        }
    }

    // Primary
    &--primary,
    &--download {
        color: $white;

        @include mq($until: $viewport--md - 1) {
            font-size: 90%;

            &::before {
                width: 100%;
            }
        }

        @include hover() {

            &::before {
                @include dimensions(100%);
                background-color: $cc-cyan;
            }

            .Icon {
                margin-right: u(15);

                &-object {
                    margin-left: 0;

                    .Icon-item--line {
                        color: $white;
                        opacity: 1;
                    }
                }
            }


            &:hover,
            &.is-hovered,
            &:focus,
            &.is-focused,
            &:active,
            &.is-active {

                &::before {
                    background-color: $cc-dark-blue;
                }

                .Icon-object {
                    animation: MoveArrow .8s infinite;
                }
            }
        }

        @include hover(none) {
            background-color: $cc-cyan;
        }
    }

    // Secondary
    &--secondary {

        .Icon {
            color: $white;
        }

        @include mq($until: $viewport--md - 1) {
            font-size: 90%;
        }

        @include hover() {

            &::before {
                background-color: $cc-cyan;
                bottom: 0;
            }

            &:hover,
            &.is-hovered,
            &:focus,
            &.is-focused,
            &:active,
            &.is-active {

                &::before {
                    background-color: $cc-cyan;

                    &::before {
                        @include dimensions(40);
                    }
                }
            }
        }

        @include hover(none) {
            background-color: $cc-cyan;
            border-radius: u(40);
            color: $white;
        }
    }

    // Success
    &--success {

        &::before {
            background-color: $green;
        }

        &:hover,
        &.is-hovered,
        &:focus,
        &.is-focused,
        &:active,
        &.is-active {
            color: $white;

            &::before {
                background-color: $green--700;
            }
        }
    }

    // Info
    &--info {

        &::before {
            background-color: $blue;
        }

        &:hover,
        &.is-hovered,
        &:focus,
        &.is-focused,
        &:active,
        &.is-active {
            color: $white;

            &::before {
                background-color: $blue--700;
            }
        }
    }

    // Warning
    &--warning {

        &::before {
            background-color: $orange;
        }

        &:hover,
        &.is-hovered,
        &:focus,
        &.is-focused,
        &:active,
        &.is-active {
            color: $white;

            &::before {
                background-color: $orange--700;
            }
        }
    }

    // Danger
    &--danger {

        &::before {
            background-color: $red;
        }

        &:hover,
        &.is-hovered,
        &:focus,
        &.is-focused,
        &:active,
        &.is-active {
            color: $white;

            &::before {
                background-color: $red--700;
            }
        }
    }

    // Small
    &--small {
        @include font-size($button-font-size * .75, false);
        padding-right: u($spacing-unit--sm);
        padding-left: u($spacing-unit--sm);
    }

    // Full
    &--full {
        width: 100%;
    }
}

@keyframes MoveArrow {
    0% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(7px, 0);
    }

    100% {
        transform: translate(0, 0);
    }
}
