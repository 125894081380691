.Block--review {

    > .Container {

        @include mq($from: 1800) {
            max-width: u(1520);
        }
    }

    .Reviews {
        display: flex;
        flex-wrap: wrap;
        margin-top: u(-30);
        margin-left: u(-45);

        > .Reviews-item {
            padding-top: u(30);
            padding-left: u(45);
        }

        @include mq($from: $viewport--md) {
            margin-top: u(-50);

            > .Reviews-item {
                padding-top: u(50);
                width: 50%;
            }
        }
    }

    .Review {
        background-color: $cc-grey--light;
        padding: u(30);

        @include mq($from: $viewport--md) {
            padding: u(50, 45, 45);
        }

        &-header {

            @include mq($until: $viewport--md - 1) {

                .Rating {
                    margin-bottom: u($spacing-unit);
                }
            }

            @include mq($from: $viewport--md) {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;

                .Rating {
                    transform: translateX(22.5px) translateY(-22.5px);
                }
            }
        }

        &-body {

            :last-child {
                margin-bottom: 0;
            }
        }

        &-title {
            font-size: 22px;
            color: $cc-black;
        }

        &-function {
            color: $cc-cyan;
            font-size: 13px;
            font-weight: 600;
            letter-spacing: px(1);
        }
    }
    @include mq($until: $viewport--lg - 1) {

        .Review {
            width: 100%;

            &-body {
                flex-direction: column;
                margin-bottom: u(15);
            }
        }
    }
}

.Rating {
    display: flex;

    &-icon {
        display: block;
        fill: $cc-cyan;

        & + & {
            margin-left: u(5);
        }
    }
}
