// Tags
.Block--tags {

    .Tags {
        background-color: $cc-black;
        min-height: u(468px);

        @include mq($from: $structure-responsive-breakpoint, $until: $structure-width--xl + 199) {

            &::before {
                @include position(absolute, $top: 0, $right: calc(100% - 40px), $bottom: 0);
                background-color: $cc-black;
                content: '';
                width: calc((100vw - 1230px) / 2);
            }
        }

        @include mq($from: $viewport--lg) {
            @include grid((100% / 1230 * 325) 1fr, 100%);
        }
    }

    .Tags-title {

        > a {
            color: currentColor;
            text-decoration: none;

            &:hover {
                color: $cc-cyan;
            }
        }
    }

    .Tags-object {
        background-color: $black;

        @include mq($from: $viewport--lg) {
            @include grid-area(1, 2, 1, 2);
            position: relative;
            flex-basis: (100% / 1230 * 325);
            flex-shrink: 0;
        }
    }

    .TagsItem-object {
        @include mq($from: $viewport--md, $until: $viewport--lg - 1) {
            padding-bottom: calc(100% / 3 * 1);
            height: 0;
            position: relative;

            img {
                @include position(absolute, 0, 0, 0, 0);
            }
        }
    }

    .Tags-body {
        align-self: center;
        color: $white;
        padding: u($spacing-unit--lg, $spacing-unit);
        flex-grow: 1;

        @include mq($until: $viewport--lg - 1) {
            text-align: center;
        }

        @include mq($from: $viewport--lg) {
            @include grid-area(2, 3, 1, 2);
            padding: u(85px, 65px, 65px);
        }
    }

    .Button--primary {

        &,
        &:hover {
            color: $white;
        }
    }
}
