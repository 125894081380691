.Block--tagline {

    &.has-bg {
        @include fluid-type(padding-bottom, $viewport--sm, $viewport--lg, 60, 120);
        margin-top: 0;

        @include mq($until: 1229) {
            background-color: $cc-grey--light;
        }

        > .Container {

            @include mq($from: $viewport--lg) {
                padding-right: u(60);
                padding-left: u(60);
            }

            @include mq($from: 1230) {

                &::before {
                    @include position(absolute, $top: 0, $left: u(60), $bottom: 0, $z: -1);
                    @include fluid-type(margin-top, $viewport--sm, $viewport--lg, -60, -120);
                    @include fluid-type(margin-bottom, $viewport--sm, $viewport--lg, -60, -120);
                    background-color: $cc-grey--light;
                    content: '';
                    margin-right: u(-80);
                    margin-left: u(-80);

                    @include mq($from: 1800) {
                        width: calc(100% + 80px + 80px + ((100vw - #{u(1600)}) / 2));
                    }

                    @include mq($from: $viewport--lg, $until: 1799) {
                        width: calc(100% + 80px + 80px + ((100vw - #{u(1080)}) / 2));
                    }

                    @include mq($until: $viewport--lg - 1) {
                        content: none;
                    }

                    @include mq($from: $viewport--lg, $until: 1300) {
                        width: 100vw;
                    }
                }
            }
        }
    }

    .CallToAction {
        max-width: u(740);
        margin-right: auto;
        margin-left: auto;
        text-align: center;

        &-title {
            @include fluid-type(font-size, $viewport--sm, $viewport--lg, 30, 50);
            font-weight: 500;
            letter-spacing: px(1);
            line-height: 1.42;
            margin-bottom: u(30);
        }
    }
}
