/**
 * Settings: Fonts
 */

// Default
$font-sans:                             "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-serif:                            Georgia, "Times New Roman", Times, serif !default;
$font-code:                             Menlo, Monaco, Consolas, monospace, serif !default;

// Custom
$font-poppins:                          "Poppins", sans-serif !default;
$font-butler:                           "butler", sans-serif !default;
