.Block--form {
    .Grid--imageRight {
        flex-flow: row wrap;
    }
    .Grid--imageLeft {
        flex-flow: row-reverse wrap;
    }

    .textImageIcon {
        margin-bottom: 1.66667rem;
        width: 4rem;
    }
}