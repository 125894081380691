.Block--information {
    @include fluid-type(padding-top, $viewport--sm, $viewport--lg, 60, 120);
    @include fluid-type(padding-bottom, $viewport--sm, $viewport--lg, 60, 120);
    overflow: hidden;

    @include mq($from: $viewport--md) {

        .Media-object:not(.is-first) {
            grid-row: 1 / 2;
        }
    }

    @include mq($until: 1229) {
        background-color: $cc-grey--light;
    }

    > .Container {

        @include mq($from: $viewport--lg) {
            padding-right: u(60);
            padding-left: u(60);

            @include parent-nth-status(1, '.has-grid', suffix) {
                @include grid(1fr u(90) 1fr, auto);
            }
        }

        @include mq($from: 1230) {

            &::before {
                @include fluid-type(margin-top, $viewport--sm, $viewport--lg, -60, -120);
                @include fluid-type(margin-bottom, $viewport--sm, $viewport--lg, -60, -120);
                @include grid-area(1, 4, 1, 6);
                background-color: $cc-grey--light;
                content: '';
                display: block;
                margin-right: u(-80);
                margin-left: u(-80);

                @include mq($from: 1800) {
                    width: calc(100% + 80px + 80px + ((100vw - #{u(1600)}) / 2));
                }

                @include mq($from: $viewport--lg, $until: 1799) {
                    width: calc(100% + 80px + 80px + ((100vw - #{u(1080)}) / 2));
                }

                @include mq($until: $viewport--lg - 1) {
                    content: none;
                }

                @include mq($from: $viewport--lg, $until: 1300) {
                    width: 100vw;
                }
            }
        }
    }
}

.Information {

    &-title {
        @include fluid-type(font-size, $viewport--sm, $viewport--lg, 32, 40);
        align-self: start;
        font-weight: 600;
        line-height: 1.25;
        letter-spacing: normal;
        max-width: u(400);
        width: 100%;

        @include mq($from: $viewport--md, $until: $viewport--lg - 1) {
            max-width: none;
        }

        @include mq($from: $viewport--lg) {
            @include grid-area(1, 2, 1, 2);
        }
    }

    &-item {

        .MediaItem-object {
            height: auto !important;
        }

        @include mq($from: $viewport--lg) {
            @include grid(1fr u(90) 1fr, auto);
            grid-column: 1 / 4;
        }

        & + & {
            margin-top: u(60);

            @include mq($from: $viewport--lg) {
                margin-top: u(120);
            }
        }

        @include mq($from: $viewport--lg) {

            @for $i from 1 through 10 {

                &:nth-of-type(#{$i}) {
                    grid-row: #{$i} / #{$i + 1};
                }
            }

            &:nth-of-type(even) {

                .Information-body {
                    grid-column: 1 / 2;
                }

                .Media-object {
                    grid-column: 3 / 4;
                }
            }

            &:nth-of-type(odd) {

                .Information-body {
                    grid-column: 3 / 4;
                }

                .Media-object {
                    grid-column: 1 / 2;
                }
            }

            &:first-of-type {

                .Media-object {
                    align-self: end;
                    grid-row: 2 / 3;
                    max-width: u(552);

                    @include mq($from: $viewport--lg) {
                        margin-top: u(-60);
                    }
                }
            }

            .Media-object.is-first {
                @include grid(46% u(90) 30%, 1fr u(150) 1fr);
                @include grid-area(1, 4, 2, 3);
                position: relative;
                margin-top: u(60);
                max-width: none;

                .Media-object:nth-child(1) {
                    @include grid-area(1, 2, 1, 4);
                    max-width: 100vw;

                    @include mq($from: $viewport--md, $until: 1200px - 1) {
                        margin-left: u($structure-gutter--l * -2);
                        width: calc(100% + #{u($structure-gutter--l * 2)});
                    }

                    @include mq($from: 1200px, $until: $structure-width--xl + 199) {
                        margin-left: calc((100vw - 1080px) / -2);
                        width: calc(100% + (100vw - 1080px) / 2);
                    }

                    @include mq($from: $structure-width--xl + 200) {
                        margin-left: calc(-5vw - 80px);
                    }

                    img {
                        width: 100%;
                        object-fit: cover;
                        height: 464px;
                    }
                }

                .Media-object:nth-child(2) {
                    @include grid-area(3, 4, 2, 3);
                    @include dimensions(380);
                    display: inline-block;
                    align-self: center;

                    .Media-image {
                        @include dimensions(100%);
                        object-fit: cover;
                    }

                    @include mq($until: 1019px) {
                        margin-top: u(30);
                        width: 100%;
                        height: auto;
                    }
                }

                .Label {
                    @include grid-area(3, 4, 3, 4);
                    @include position(absolute, $top: 80%);
                    float: right;
                    width: u(380);
                }
            }
        }

        @include mq($until: $viewport--lg - 1) {

            .Media-object.is-first {

                .Media-object + .Media-object {
                    margin-top: u(30);
                }
            }
        }
    }
}
