.Block--team {

    .Grid-cell {
        display: flex;
        flex-direction: column;
    }

    .CallToAction {
        background-color: $cc-grey--light;
        padding: u($spacing-unit--lg);
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        text-align: center;

        &-body {
            max-width: u(460);
        }
    }
}
