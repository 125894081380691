// More
.Block--more {
    text-align: center;

    .Block + &,
    & + .Block {
        margin-top: u(90px);

        .BlogDetailPage & {
            margin-top: u(130px);
        }
    }

    .Main &:last-child {
        margin-bottom: u(90px);

        .BlogDetailPage & {
            margin-bottom: u(130px);
        }
    }
}
