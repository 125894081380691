/* Label */
.Label {
    @include font-size(15, false);
    color: $cc-grey--dark;
    display: block;
    font-weight: 300;
    letter-spacing: 1.1px;
    line-height: 1.4;
    margin-bottom: 0;

    &-link {

        &,
        &:active,
        &:focus,
        &:hover {
            color: currentColor;
            text-decoration: none;
        }

        &:hover {
            color: $cc-cyan;
        }
    }

    &--intro {
        margin-bottom: u(20px);
    }

    &--withLine {
        display: flex;
        align-items: center;

        &::after {
            content: '';
        }
    }

    &--top,
    &--bottom {
        left: 100%;

        @include mq($from: $viewport--md) {
            @include position(absolute, $bottom: 0);
            flex-direction: row-reverse;
            transform-origin: 0 100%;
            white-space: nowrap;
            margin-bottom: 0;
        }

        &::after {
            border-bottom: 1px solid $cc-grey--dark;
            width: u(54px);
            margin-right: u($spacing-unit--sm);
        }
    }

    &--right {

        @include mq($until: $viewport--lg - 1) {
            @include position(absolute, $right: 0, $bottom: 0);
            flex-direction: row-reverse;
            transform: rotate(90deg) translateX(-100%) translateY(-100%);
            transform-origin: left top 0;
            margin-bottom: 0;
            white-space: nowrap;
            width: u($structure-gutter--r);

            // @supports (text-orientation: mixed) or (-webkit-text-orientation: mixed) {
            //     transform: none;
            //     writing-mode: vertical-rl;
            //     text-orientation: mixed;
            // }
        }

        &::after {

            // @include mq($from: $viewport--sm, $until: $viewport--lg - 1) {
            //     border-left: 1px solid $cc-grey--dark;
            //     height: u(54px);
            //     margin-bottom: u($spacing-unit--sm);
            // }

            @include mq($from: $viewport--lg) {
                border-top: 1px solid $cc-grey--dark;
                margin-left: u($spacing-unit--sm);
                width: u(54px);
            }
        }

    }

    &--left {
        flex-direction: row-reverse;

        &::after {
            border-top: 1px solid $cc-grey--dark;
            margin-right: u($spacing-unit--sm);
            width: u(54px);
        }
    }

    &--top {
        @include mq($from: $structure-responsive-breakpoint + 60) {
            transform: rotate(90deg) translateX(-100%);
        }

        @include mq($from: $viewport--md, $until: $structure-responsive-breakpoint + 60 - 1) {
            transform: rotate(90deg) translateX(-100%) translateY(29px);
        }
    }

    &--bottom {
        @include mq($from: $structure-responsive-breakpoint + 60) {
            transform: rotate(-90deg) translateY(-30px);
        }

        @include mq($from: $viewport--md, $until: $structure-responsive-breakpoint + 60 - 1) {
            transform: rotate(-90deg) translateY(100%) translateY(-100%) translateY(-30px);
        }
    }
}
