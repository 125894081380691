.Nav--faq {

    > .Nav-list {

        > .Nav-item {

            + .Nav-item {
                margin-top: u(15);
            }

            > .Nav-link {
                font-weight: 600;
                letter-spacing: px(0.73);
            }
        }
    }

    .Nav-link {

        &,
        &:active,
        &:focus,
        &:hover {
            color: currentColor;
        }

        &:hover {
            color: $cc-cyan;
        }
    }
}

.Nav--faqTags {

    .Nav-list {
        list-style-type: disc;
        padding-left: 1.15em;
    }

    .Nav-item {

        + .Nav-item {
            margin-top: u(2);
        }
    }

    .Nav-item {

    }
}
