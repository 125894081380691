/* Share */
.Nav--share {
    display: flex;
    align-items: center;
    margin-top: u(70px);

    .Nav-item {

        + .Nav-item {
            margin-left: u($spacing-unit--sm);
        }
    }

    .Nav-list {
        margin-left: u(40px);
    }

    .Nav-link {
        @include transition(color, 200ms);
        color: rgba($black, .5);

        &:hover {
            color: $cc-cyan;
        }
    }

    svg {
        display: block;
        fill: currentColor;
        height: 24px;
        width: 24px;
    }
}
