/**
 * Logo
 */

.Logo {
    height: u(50px);
    width: u(204px);

    &,
    &:hover,
    &:active,
    &:focus {
        color: currentColor;
    }
}