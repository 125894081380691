// Columns
.Block--columns {
    overflow: hidden;

    .Label {
        @include grid-area(1, 2, 4, 5);
        margin-top: u($spacing-unit--sm);
        // grid-area: label;
        justify-self: end;

        @include mq($until: $viewport--lg - 1) {
            display: none;
        }
    }

    .Columns {

        a:not(.Button) {
            text-decoration: none;

            &, &:hover {
                color: currentColor;
            }
        }

        @include mq($from: $viewport--lg) {
            display: grid;
            grid-template-rows: 80px auto 80px auto;
            grid-template-columns: #{(100% / 1230 * 325)} 11% 1fr;
            // grid-template-areas: ". ." "object body" "object ." "label .";
            // grid-column-gap: 11%;
        }

        @include mq($from: $viewport--lg) {

            &:not(.Columns--cta) {

                &::before {
                    width: calc(100% + #{u($structure-responsive-gutter--l)});

                    @include mq($from: $viewport--md, $until: $structure-responsive-breakpoint - 1) {
                        width: calc(100% + #{u($structure-gutter--l)});
                    }

                    @include mq($from: $structure-responsive-breakpoint) {
                        width: calc(100% + ((100vw - #{u($structure-width)}) / 2));
                    }
                }
            }

            &::before {
                background-color: $cc-grey--light;
                content: '';
                display: block;
                grid-row: 1 / 3;
                grid-column: 1 / 4;
            }
        }

        &--cta {
            grid-template-columns: 1fr 1fr;

            .Column-object {
                @include dimensions(100%);

                &.object-fit-polyfill {
                    height: u(468px);
                }
            }

            .Columns-object {
                max-width: none;

                img {
                    @include dimensions(100%);
                    object-fit: cover;

                    @include mq($from: $viewport--lg) {
                        max-height: u(468px);
                    }
                }

                @include mq($from: $viewport--lg, $until: $structure-responsive-breakpoint - 1) {
                    margin-left: u($structure-gutter--l * -1);
                    width: calc(100% + #{u($structure-gutter--l)});
                }

                @include mq($from: $structure-responsive-breakpoint, $until: $structure-width--xl + 199) {
                    margin-left: calc((100vw - 1230px) / -2);
                    width: calc(100% + (100vw - 1230px) / 2);
                }

                @include mq($from: $structure-width--xl + 200) {
                    margin-left: -5vw;
                }
            }

            .Columns-body {
                grid-column: 2 / 3;
                align-self: center;
                padding-top: u($spacing-unit);
                padding-bottom: u($spacing-unit);

                @include mq($from: $viewport--lg) {
                    margin-left: u(90px);
                    padding-right: u(60px);
                }
            }
        }
    }

    .Columns-body {
        @include grid-area(3, 4, 2, 3);
        background-color: $cc-grey--light;
        padding: u($spacing-unit--lg, $spacing-unit);
        // grid-area: body;

        @include mq($until: $viewport--lg - 1) {
            text-align: center;
        }

        @include mq($from: $viewport--lg) {
            padding: u($spacing-unit--xs, 0, $spacing-unit * 2.4);
        }
    }

    .Columns-object {
        @include grid-area(1, 2, 2, 4);
        height: 100%;
        // grid-area: object;
        position: relative;
        // width: 100%;

        @include mq($from: $viewport--md, $until: $viewport--lg - 1) {
            padding-bottom: calc(100% / 16 * 9);
            height: 0;
            position: relative;

            > img,
            > .Column-object > img {
                @include dimensions(100%);
                object-fit: cover;
            }

            > img,
            > .Column-object {
                @include position(absolute, 0, 0, 0, 0);
            }
        }
    }


}
