/**
 * Heading
 */

a {
    color: $link-color;
    text-decoration: $link-text-decoration;
    transition: color .2s;

    &:hover,
    &:focus,
    &:active {
        color: $link-color--hover;
        text-decoration: $link-text-decoration--hover;
    }
}

.BlogDetailPage .Block--content{
    a {
        font-weight: $link-fontWeight;
    }
}