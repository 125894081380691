/* Main */
.Nav--main {
    @include font-size(25, false);
    position: relative;

    @include mq($until: $viewport--md - 1) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
    }

    @include mq($from: $viewport--md) {
        @include font-size(13, false);
        text-transform: uppercase;
        line-height: 1.77;
        letter-spacing: px(1);
        flex-shrink: 0;

        .Nav-magicLine {
            @include position(absolute, $top: 0, $right: 0, $left: 0);
            @include transition(opacity, 200ms);
            border-top: px(5) solid $cc-black;
            display: block;

            .Header--black & {
                border-top-color: $white;
            }

            &:not(.is-loaded) {
                opacity: 0;
            }

            @include mq($until: $viewport--md - 1) {
                display: none;
            }

            @include mq($from: $viewport--md, $until: $viewport--lg - 1) {
                margin-left: u($spacing-unit);
            }

            @include mq($from: $viewport--lg) {
                margin-left: u($spacing-unit--lg);
            }
        }
    }

    .Nav-list {
        flex-grow: 1;
        height: u($structure-header-height);

        @include mq($until: $viewport--md - 1) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: calc(100% - #{u($structure-header-height)});
            padding-bottom: u($spacing-unit--lg * 1.5);
        }
    }

    .Nav-item {

        @include mq($until: $viewport--md - 1) {

            + .Nav-item {
                margin-top: u(10);
            }

            .show-nav & {
                @include transition(#{opacity, transform}, #{300ms, 1s}, #{linear, cubic-bezier(.19,1,.22,1)});

                @for $i from 1 through 10 {
                    $delay: (0.02s * $i);

                    &:nth-child(#{$i}) {
                        transition-delay: $delay;
                    }
                }
            }
        }

        @include mq($from: $viewport--md) {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
        }

        @include mq($from: $viewport--md, $until: $viewport--lg - 1) {
            margin-left: u($spacing-unit);
        }

        @include mq($from: $viewport--lg) {
            margin-left: u($spacing-unit--lg);
        }
    }

    .Nav-link {

        &,
        &:active,
        &:focus,
        &:hover {
            color: currentColor;
        }

        &:not(.Nav-link--button).is-active {
            font-weight: 600;
        }

        @include mq($from: $viewport--md) {

            &--button {
                @include transition(#{background-color, color}, 200ms);
                background-color: $cc-black;
                border-radius: u(33px);
                font-weight: 500;
                padding: u(5, 20);

                .Header--black & {
                    background-color: $cc-cyan;
                }

                &,
                &:active,
                &:focus,
                &:hover,
                &.is-active {
                    color: $white;
                }

                &.is-active,
                &:hover {
                    background-color: $cc-cyan;
                }
            }
        }
    }
}
