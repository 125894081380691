// Media
.Block--media {

    &.has-bg {
        @include fluid-type(padding-bottom, $viewport--sm, $viewport--lg, 60, 120);
        margin-top: 0;

        @include mq($until: 1229) {
            background-color: $cc-grey--light;
        }

        > .Container {

            @include mq($from: $viewport--lg) {
                padding-right: u(60);
                padding-left: u(60);
            }

            @include mq($from: 1230) {

                &::before {
                    @include position(absolute, $top: 0, $left: u(60), $bottom: 0, $z: -1);
                    @include fluid-type(margin-top, $viewport--sm, $viewport--lg, -60, -120);
                    @include fluid-type(margin-bottom, $viewport--sm, $viewport--lg, -60, -120);
                    background-color: $cc-grey--light;
                    content: '';
                    margin-right: u(-80);
                    margin-left: u(-80);

                    @include mq($from: 1800) {
                        width: calc(100% + 80px + 80px + ((100vw - #{u(1600)}) / 2));
                    }

                    @include mq($from: $viewport--lg, $until: 1799) {
                        width: calc(100% + 80px + 80px + ((100vw - #{u(1080)}) / 2));
                    }

                    @include mq($until: $viewport--lg - 1) {
                        content: none;
                    }

                    @include mq($from: $viewport--lg, $until: 1300) {
                        width: 100vw;
                    }
                }
            }
        }
    }

    // & + .Block {
    //     margin-top: 0;
    // }

    &:first-child {
        background-color: $cc-black;
        color: $white;
        margin-top: u($structure-header-height * - 1);
        padding-top: u($structure-header-height);
    }

    > .Container {
        display: flex;
    }

    .Media {

        @include mq($from: $viewport--md) {
            min-height: calc(100vh - #{u($structure-header-height)});
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &-scroll {
            @include dimensions(70);
            @include position(absolute, $right: u($structure-responsive-gutter--r), $bottom: 0);
            background-color: $white;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @include mq($from: $viewport--md) {
                right: if($structure-responsive-gutter--r > 0 and $structure-gutter--r != $structure-responsive-gutter--r, u($structure-gutter--r), null);
            }

            .Mouse {
                border-color: $cc-black;

                &::before {
                    background-color: $cc-black;
                }
            }
        }
    }

    .Media-object,
    .Media-body {

        @include mq($from: $viewport--md) {
            width: 100%;
        }
    }

    .Media-object {

        @include mq($from: $viewport--md) {

            &-bg::before {
                @include dimensions(20%, 26%);
                @include position(absolute, $z: 0);
                background-color: rgba($white, 0.2);
                content: '';
                display: block;

                @include mq($from: $structure-width--xl + 200) {
                    @include dimensions(600, 660);
                    transform: translate(#{u(-60)}, #{u(60)});
                }

                @include mq($from: $viewport--md, $until: $structure-width--xl + 200 - 1) {
                    @include dimensions(40%, 450);
                    transform: translate(#{u(-60)}, #{u(60)});
                }

                @include mq($from: $viewport--sm, $until: $structure-width--md  - 1) {
                    @include dimensions(360, 300);
                    transform: translate(0, 5%);
                }
            }
        }

        @include mq($from: $viewport--md) {

            @include parentState(':first-child') {
                @include grid(1fr u(50) 1.5fr, auto u(150) auto);
                max-width: 52%;
            }
        }

        &--fullscreen {
            @include position(relative, $right: 50%, $left: 50%);
            margin-right: -50vw;
            margin-left: -50vw;
            width: 100vw;
        }

        @include parentState(':first-child') {

            @include mq($until: $viewport--md - 1) {
                margin-right: u($structure-responsive-gutter--r * -1);
                margin-left: u($structure-responsive-gutter--l * -1);
            }
        }
    }

    .MediaItem-object {
        position: relative;

        &--video {
            // width: 100vw;

            // &:nth-child(1) {

            //     @include mq($from: 1800) {
            //         max-width: u(465);
            //     }
            // }

            // &:nth-child(2) {
            //     max-width: u(355);
            // }
        }

        img {
            width: 100%;
        }

        video {
            object-fit: cover;
        }

        @include parentState(':first-child') {

            &:nth-child(1) {
                @include grid-area(2, 4, 1, 3);
                z-index: 1;

                .FlexEmbed,
                img {

                    @include mq($until: $viewport--md - 1) {
                        object-fit: cover;
                        height: u(270px);
                    }
                }
            }

            &:nth-child(2) {
                @include grid-area(1, 3, 2, 4);
                z-index: 2;

                @include mq($until: $viewport--md - 1) {
                    display: none;
                }

                @include mq($from: $viewport--md, $until: $structure-width--xl + 200 - 1) {
                    max-width: u(260px);
                    justify-self: end;
                    width: 100%;
                }

                img {
                    box-shadow: 5px 5px 16px 0 rgba(0, 0, 0, 0.08);
                }
            }
        }
    }

    .Media-body {
        @include position(relative, $z: 1);
        padding: u($spacing-unit--lg, $spacing-unit);
        margin-top: u($spacing-unit--lg * -1);

        @include mq($until: $viewport--md - 1) {
            background-color: $cc-black;
        }

        @include mq($from: $viewport--md) {
            max-width: 40%;
            margin-top: 0;
            padding: 0;
        }
    }
}
