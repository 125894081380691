// Customers
.Block--customers {
    background-color: $cc-grey--light;
    padding-top: u($spacing-unit--lg);
    padding-bottom: u($spacing-unit--lg);

    @include mq($from: $viewport--md, $until: $viewport--lg - 1) {
        padding-top: u($spacing-unit--xl);
        padding-bottom: u($spacing-unit--xl);
    }

    @include mq($from: $viewport--lg) {
        padding-top: u(190);
        padding-bottom: u(60);

        .ServiceOverviewPage & {
            padding-top: u(190);
            padding-bottom: u(120);
        }

        .Block + & {
            margin-top: u(-90px);
        }
    }

    > .Container {
        @include mq($from: $viewport--md) {
            @include grid(100px 40% 50px 1fr 100px, auto auto);
        }
    }

    .Customer-title {
        @include grid-area(2, 3, 1, 2);
    }

    .Customer-body {
        @include grid-area(4, 5, 1, 2);
    }

    .Customer-object {
        @include grid-area(4, 6, 2, 3);

    }

    .Customer-logo {
        @include transition(filter, 200ms);
        filter: grayscale(100%);

        &:hover {
            filter: grayscale(0%);
        }
    }

    .Customer-logo,
    .Customer-object {
        display: block;
    }
}

.Block--clients {

    .Slider-items {
        align-items: center;
    }

    .Client {

        &-logo {

            .Client-image {
                @include transition(filter, 200ms);
                filter: grayscale(100%);
            }

            &:hover {

                .Client-image {
                    filter: grayscale(0%);
                }
            }
        }

        &-image {
            margin-right: auto;
            margin-left: auto;
        }

        &-object,
        &-image {
            display: block;
        }
    }
}
