/**
 * Objects: Form
 */

// Namespace
$form-namespace:                    $namespace !default;


// Item spacing
$form-item-spacing:                 $spacing-unit--sm !default;

// Label spacing
$form-label-spacing--t:             $spacing-unit--xs !default;
$form-label-spacing--b:             $spacing-unit--xs !default;

// Help & Validation
$form-help-font-size:               $font-size * 0.75 !default;

// Horizontal
$form-label-width:                  1/3 !default;
$form-controls-width:               2/3 !default;

// Colors & Styles
$form-help-color:                   $grey--400 !default;
$form-divider-color:                $grey--300 !default;

$form-error-color:                  $red !default;
$form-error-element-box-shadow:     0 0 2px 1px rgba($form-error-color, 0.6) !default;
$form-success-color:                $green !default;
$form-success-element-box-shadow:   0 0 2px 1px rgba($form-success-color, 0.6) !default;
