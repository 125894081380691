.Block--cases {

    .Label:not(:last-child) {
        margin-bottom: u(20);
    }

    .Block-header {
        margin-bottom: u(50);
    }

    .Nav--pagination {
        margin-top: u($spacing-unit--lg);

        @include mq($from: $viewport--md) {
            margin-top: u($spacing-unit--xl);
        }
    }
}
