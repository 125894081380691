/* Slider: navigation */
.Slider-button {
    @include dimensions(36);
    @include position(absolute, $top: 50%, $z: 10);
    @include transition(#{background-color, color, opacity});
    background-color: $cc-cyan;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: u(14, 9.33);
    border-radius: u(50);
    cursor: pointer;
    display: block;
    margin-top: u(-18);

    @include mq($from: $viewport--md) {
        @include dimensions(48);
        background-size: u(21, 14);
        margin-top: u(-24);
    }

    &:hover {
        background-color: $cc-dark-blue;
    }

    &-black {

        &::before {
            @include transition(#{background-color, width}, 300ms);
            @include position(absolute, $top: 0, $left: 0, $z: -1);
            background-color: $cc-cyan;
            border-radius: u(40px);
            content: '';
            height: 150%;
            width: 60px;
        }
    }

    &.is-disabled {
        cursor: auto;
        opacity: 0.35;
    }

    &.is-locked {
        display: none;
    }
}

.Slider-button--prev,
.Slider--rtl .Slider-button--next {
    background-image: url('#{$path-img}galleryPrev.svg');
    right: auto;
    left: 0;
}

.Slider-button--next,
.Slider--rtl .Slider-button--prev {
    background-image: url('#{$path-img}galleryNext.svg');
    right: 0;
    left: auto;
}
